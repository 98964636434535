import React from 'react';

import 'swiper/css/swiper.min.css';
import 'nouislider/distribute/nouislider.css';
import "react-datepicker/dist/react-datepicker.css";
import './App.css';

import {
    Switch,
    Route, Redirect, withRouter
} from 'react-router-dom';

import Home from "./components/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Shop from "./components/Shop/Shop";
import Blog from "./components/Blog/Blog";
import BlogPost from "./components/BlogPost/BlogPost";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Checkout from "./components/Checkout/Checkout";
import Career from "./components/Career/Career";
import SingleJob from "./components/SingleJob/SingleJob";
import DynamicPage from "./components/DynamicPage/DynamicPage";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import Loader from "./components/common/Loader/Loader";
import Categories from "./components/Categories/Categories";
import BusyModal from "./components/common/BusyModal/BusyModal";
import LocationModal from "./components/LocationModal/LocationModal";

import {connect} from "react-redux";
import {chooseUserBranch, getGeneralData, getUserBranch} from "./store/reducers/appReducer";
import withLang from "./hoc/withLang/withLang";
import {compose} from "redux";
import {getIconAddress} from "./helpers/helpers";

// social media and tracking
import ReactGA from 'react-ga';
import FbMessenger from "./components/common/FbMessenger/FbMessenger";
import ReactPixel from 'react-facebook-pixel';


class App extends React.Component {

    generateLangChangeUrl = () => {
        const queryParams = new URLSearchParams(this.props.location.search);

        const newLang = this.props.lang === 'ge' ? 'en' : 'ge';

        queryParams.set('lang', newLang);
        return '?' + queryParams.toString();
    }

    componentDidMount() {
        this.props.getGeneralData();
        this.props.getUserBranch();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.langId !== prevProps.langId) {
            this.props.getGeneralData();
        }

        if (prevProps.general !== this.props.general) {
            // set site title
            document.title = this.props.general.website_title;

            // dynamically change favicon
            const link = document.querySelector('#favicon');
            link.href = getIconAddress(this.props.general);

            /*
             set-up google analytics
             and make it track pageviews
            */
            if(this.props.general.integrations.integration_google_analytics_tracking_id){
                ReactGA.initialize(this.props.general.integrations.integration_google_analytics_tracking_id,
                    {
                        debug: true
                    });

                ReactGA.pageview(window.location.pathname + window.location.search);
            }
            // /.

            // facebook pixel setup
            if(this.props.general.integrations.integration_facebook_pixel_id){
                ReactPixel.init(this.props.general.integrations.integration_facebook_pixel_id, undefined, {
                    autoConfig: true,
                    debug: false,
                });
                ReactPixel.pageView(); // For tracking page view

            }



        }

    }

    render() {


        if (!this.props.general || !this.props.languages || this.props.languages.length === 0 || this.props.branchIsLoading) {
            return <Loader/>
        }


        return (
            <>
                <Navbar
                    newLangUrl={this.generateLangChangeUrl()}
                    languages={this.props.languages}
                    langId={this.props.langId}
                    chooseLangHandler={this.props.chooseLangHandler}
                />
                <div className={'d-flex flex-column'} style={{height: '100%'}}>

                    <div style={{flex: '1 0 auto'}}>

                        <BusyModal
                            generalData={this.props.general}
                        />

                        <LocationModal
                            generalData={this.props.general}
                        />

                        <Switch>
                            <Route exact path={'/'} component={Home}/>

                            <Route exact path={'/shop'} component={Shop}/>
                            <Route exact path={'/categories'} component={Categories}/>


                            <Route exact path={'/blog'} component={Blog}/>
                            <Route exact path={'/blog/:id'} component={BlogPost}/>
                            <Route exact path={'/about'} component={About}/>
                            <Route exact path={'/contact'}
                                   render={(routeProps) => <Contact {...routeProps} general={this.props.general}/>}/>
                            <Route exact path={'/checkout'} component={Checkout}/>

                            <Route exact path={'/career/:id'} component={SingleJob}/>
                            <Route exact path={'/career'} component={Career}/>

                            {/* dynamic pages  */}
                            <Route path={'/pages/:pageName'} component={DynamicPage}/>
                            <Route exact path={'/pages'} component={PageNotFound}/>
                            {/* /. */}

                            {/* invoice */}
                            <Route path={'/invoice/:id'} render={() => <Redirect to={'/'}/>}/>
                            {/* /. */}

                            <Route path={'*'} component={PageNotFound}/>

                        </Switch>
                    </div>


                    <div style={{flexShrink: '0'}}>
                        <Footer
                            general={this.props.general}
                        />
                    </div>

                    <FbMessenger
                        pageId={this.props.general.integrations.integration_facebook_messenger_page_id}
                    />

                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    lang: state.app.lang,
    general: state.app.generalData,
    languages: state.app.languages,
    langId: state.app.langId,
    showMainAlert: state.app.showMainAlert,
    branchData: state.app.branchData,
    branchIsLoading: state.app.branchIsLoading,
});

const mapDispatchToProps = dispatch => ({
    getGeneralData: () => dispatch(getGeneralData()),
    getUserBranch: () => dispatch(getUserBranch()),
    chooseUserBranch: (address, latitude, longitude) => dispatch(chooseUserBranch(address, latitude, longitude)),
});


export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
)(App);
