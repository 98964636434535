//action constants
import apiHandler from "../../api/api";

const SET_PRODUCTS = 'SET_PRODUCTS';
const SET_CATEGORIES = 'SET_CATEGORIES';

//initial state
const initState = {
   categories: [],
   products: [],
   minPriceEdge: undefined,
   maxPriceEdge: undefined,
   count: 0,
}

export const PRODUCTS_LIMIT = 12;

//reducer
const shopPageReducer = (state = initState, action) => {
   switch (action.type) {
      case SET_PRODUCTS:
         // debugger;
         return {
            ...state,
            products: action.products,
            minPriceEdge: action.minPriceEdge,
            maxPriceEdge: action.maxPriceEdge,
            count: action.count,
         }
      case SET_CATEGORIES:
         return {
            ...state,
            categories: action.categories
         }
      default:
         return state;
   }
}

export default shopPageReducer;


//action creators
export const getProducts = (dataObj, page) => {
   return dispatch => {

      const {search, sort, minPrice, maxPrice} = dataObj;

      let priceObj = {}
      if(minPrice && maxPrice){
         priceObj = {
            min: minPrice,
            max: maxPrice
         };
      }

      const reqObj = {
         SearchPhrase: search,
         Order: {
            price: sort,
         },
         Filters: {
            price: priceObj,
         },
         Pagination: {
            limit: PRODUCTS_LIMIT,
            page: page,
         }
      }

      // debugger

      return apiHandler('getProducts', reqObj)
         .then(res => {
            dispatch({
               type: SET_PRODUCTS,
               products: res.Products ?? [],
               minPriceEdge: res.Min_Price ? parseFloat(res.Min_Price) : undefined,
               maxPriceEdge: res.Max_Price ? parseFloat(res.Max_Price) : undefined,
               count: res.Count ?? 0,
            })
         });
   }
}

export const getProductsByCategory = (dataObj, page) => {
   return dispatch => {

      const {catId, sort, minPrice, maxPrice} = dataObj;

      let priceObj = {}
      if(minPrice && maxPrice){
         priceObj = {
            min: minPrice,
            max: maxPrice
         };
      }

      const reqObj = {
         Categoryid: catId,
         Order: {
            price: sort,
         },
         Filters: {
            price: priceObj
         },
         Pagination: {
            limit: PRODUCTS_LIMIT,
            page: page,
         }
      }

      return apiHandler('getProductsByCategory', reqObj)
         .then(res => {
            // debugger;
            dispatch({
               type: SET_PRODUCTS,
               products: res.Products ? res.Products : [],
               minPriceEdge: res.Min_Price ? parseFloat(res.Min_Price) : undefined,
               maxPriceEdge: res.Max_Price ? parseFloat(res.Max_Price) : undefined,
               count: res.Count ?? 0,
            })
         });
   }
}

export const getCategories = () => {
   return dispatch => {
      apiHandler('getCategories')
         .then(res => {
            dispatch({
               type: SET_CATEGORIES,
               categories: res.map(el => ({
                  id: el.id,
                  name: el.name,
                  image_name: el.image_name,
               }))
            })
         });
   }
}



