import React from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import s from './CheckoutMap.module.css';
import Geocode from "react-geocode";
import {mobileCheck} from "../../helpers/helpers";


class CheckoutMap extends React.PureComponent {


   onMapClicked = (mapProps, map, clickEvent) => {
      if(!this.props.disabled){
         // props.mapMarker
         const lat = clickEvent.latLng.lat();
         const lng = clickEvent.latLng.lng();

         Geocode.fromLatLng(lat, lng).then(
            response => {
               const address = response.results[0].formatted_address;
               this.props.setAddressValue(address);
               this.props.setMapMarker(lat, lng);
            },
            error => {
               console.error(error);
            }
         )

      }
   }




   componentDidMount() {
      Geocode.setApiKey('AIzaSyDhmUw1pMO8HBhcbwu0-axtqIwxYF3C4KY');
      Geocode.setLanguage("en");

      // get current location
      if (navigator.geolocation && (!this.props.mapMarker.x && !this.props.mapMarker.y) && !this.props.disabled) {
         navigator.geolocation.getCurrentPosition((pos) => {
            // set map marker
            if(mobileCheck()) {
               this.props.setMapMarker(pos.coords.latitude, pos.coords.longitude);
            } else {
               this.props.setMapMarker(pos.coords.latitude, pos.coords.longitude, true, false);
            }

            Geocode.fromLatLng(pos.coords.latitude, pos.coords.longitude).then(
               response => {
                  const address = response.results[0].formatted_address;
                  if(mobileCheck()){
                     this.props.setAddressValue(address);
                  }
               },
               error => {
                  console.error(error);
               }
            );
         });
      }
      // /.
   }

   componentDidUpdate(prevProps, prevState, snapshot) {

      if(prevProps.addressValue !== this.props.addressValue){
         if(!this.props.addressValue){
            this.props.setMapMarker(null, null);
         } else {
            Geocode.fromAddress(this.props.addressValue).then(
                  response => {
                     const address = response.results[0].formatted_address;
                     const latLngObj = response.results[0].geometry.location;
                     this.props.setMapMarker(latLngObj.lat, latLngObj.lng, true);
                  },
                  error => {
                     console.error(error);
                  }
            )
         }

      }
   }

   render() {

      const markerJSX = () => {
         if (this.props.mapMarker.x && this.props.mapMarker.y) {
            return <Marker position={{lat: this.props.mapMarker.x, lng: this.props.mapMarker.y}}/>
         }
         return null
      }

      return (
         <div className={`position-relative h-100 ${s.mapWrp}`}>
            <Map
               onClick={this.onMapClicked}
               style={{
                  width: '100%',
                  height: '100%',
                  position: 'relative'
               }}
               containerStyle={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: '0',
                  left: '0'
               }}
               google={this.props.google}
               initialCenter={{
                  lat: this.props.mapMarker.centerX,
                  lng: this.props.mapMarker.centerY,
               }}
               center={{
                  lat: this.props.mapMarker.centerX,
                  lng: this.props.mapMarker.centerY,
               }}
               zoom={14}
            >
               {markerJSX()}
            </Map>
         </div>
      );
   }
}

export default GoogleApiWrapper({
   apiKey: 'AIzaSyDhmUw1pMO8HBhcbwu0-axtqIwxYF3C4KY'
})(CheckoutMap);