import React, {Component} from "react";
import s from './Contact.module.css';
import TitleBanner from "../common/TitleBanner/TitleBanner";
import bannerImg from "../../assets/images/4.jpg";
import {Col, Container, Row} from "react-bootstrap";
import withStr from "../../hoc/withStr";
import ContactMap from "./ContactMap";
import {getBranches} from "../../store/reducers/homePageReducer";
import {connect} from "react-redux";
import Branch from "./Branch";
import {imagePrepath, pageImageNames} from "../../helpers/helpers";
import bannerPlaceholder from "../../assets/images/banner-placeholder.jpg";


class Contact extends Component {

    componentDidMount() {
        this.props.getBranches();
    }

    render() {

        const imageName = this.props.general[pageImageNames.contact];
        const bannerImagePath = imageName ? imagePrepath(imageName) : bannerPlaceholder;

        return (
            <div>
                <TitleBanner imageURL={bannerImagePath} text={this.props.getStr('nav_contact')}/>
                <Container className={s.wrp}>

                    {this.props.branches.map(el => (
                        <Branch
                            latitude={el.latitude}
                            longitude={el.longitude}
                            address={el.address}
                            phone={el.phone}
                            email={el.email}

                            general={this.props.general}
                            getStr={this.props.getStr}
                        />
                    ))}

                </Container>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    getBranches: () => dispatch(getBranches()),
})

const mapStateToProps = (state) => ({
    general: state.app.generalData,
    branches: state.homePage.branches,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStr(Contact));