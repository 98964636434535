import React, {Component, createRef} from "react";
import s from "./CategorySlider.module.css";
import SliderPagination from "../../common/SliderPagination/SliderPagination";
import Swiper from 'swiper';
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import {getProduct} from "../../../api/api";
import BtnPriceSide from "../../common/BtnPriceSide/BtnPriceSide";
import BaseBtn from "../../common/NormalBtns/BaseBtn";
import Categories from "../../Categories/Categories";
import withStr from "../../../hoc/withStr";
import {imagePrepath} from "../../../helpers/helpers";

class CategorySlider extends Component {
    swiperRef = createRef();

    state = {
        slides: [],
        activeIndex: 0,
    }

    componentWillUnmount() {
        this.setState({
            slides: [],
            activeIndex: 0,
        });

        if(this.props.categories.length > 0 && this.swiperInstance){
            this.swiperInstance.destroy();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.categories !== this.props.categories && this.props.categories.length > 0){
            this.swiperInstance = new Swiper(this.swiperRef.current, {
                // Default parameters
                // autoplay: {
                //     delay: 5000,
                //     disableOnInteraction: false,
                // },
                slidesPerView: 'auto',
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });

            this.swiperInstance.on('slideChange', () => {
                this.setState({
                    activeIndex: this.swiperInstance.activeIndex
                })
            })
        }
    }

    categoriesToSlidesData = () => {
        return this.props.categories.map(cat => ({
            id: cat.id,
            imgLink: cat.image_name,
            name: cat.name,
        }));
    }

    render(){
        const slidesArr = this.categoriesToSlidesData();


        const slidesJSX = () => {
            if(slidesArr.length > 0) {
                return slidesArr.map((el, i) => {
                        let btnTypeName = 'White';
                        let active = false;
                        if (this.props.chosenCatId === el.id) {
                            btnTypeName = 'Yellow';
                            active = true;
                        }

                        return (
                            <Link key={i} to={`/shop?catId=${el.id}`} className={`swiper-slide ${s.swiperSlideCustom}`}>
                                <BaseBtn
                                    className={'font-weight-bold headline-font btn-border-rounded my-3 mx-3 px-4'}
                                    active={active}
                                    btnType={btnTypeName}
                                    size={'md'}
                                    text={el.name}
                                />
                            </Link>
                        )

                    }
                )
            }
            return null;
        }

        return (
            <Container>
                <div className={s.wrp}>
                    <h1 className={`${s.title} text-center`}>{this.props.getStr('categories')}</h1>
                    <div ref={this.swiperRef} className={`swiper-container ${s.swiperContainer}`}>
                        <div className="swiper-wrapper">
                            {slidesJSX()}
                        </div>
                        <div className="swiper-button-next"></div>
                        <div className="swiper-button-prev"></div>

                        {/*<SliderPagination*/}
                        {/*    posAbs={true}*/}
                        {/*    light={true}*/}
                        {/*    slides={slidesArr}*/}
                        {/*    activeIndex={this.state.activeIndex}/>*/}

                    </div>
                </div>
            </Container>
        )
    }
}

export default withStr(CategorySlider);