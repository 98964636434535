import React, {Component, createRef} from 'react';
import gsap from 'gsap';
import s from './LocationModal.module.css';
import {chooseUserBranch, hideLocationModal, showLocationModal} from "../../store/reducers/appReducer";
import CheckoutMap from '../Checkout/CheckoutMap';
import {connect} from "react-redux";
import BaseBtn from "../common/NormalBtns/BaseBtn";
import withStr from "../../hoc/withStr";
import {mobileCheck} from "../../helpers/helpers";

class LocationModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            inputVal: '',
            submittedInputVal: '',
            addressFocused: false,
            mapMarker: {
                x: null,
                y: null,
                centerX: 41.7197567,
                centerY: 44.767644499999996,
            },
        }

        if(props.branchData){
            this.state.inputVal = props.branchData.address;
            this.state.submittedInputVal = props.branchData.address;
            this.state.mapMarker = {
                x: props.branchData.latitude,
                y: props.branchData.longitude,
                centerX: props.branchData.latitude,
                centerY: props.branchData.longitude,
            }
        }
    }





    modalRef = createRef();
    addressSearchBtnRef = createRef();

    componentDidMount() {
        if(!this.props.showLocationModal){
            this.hideAlertAnimation(0)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((prevProps.showLocationModal !== this.props.showLocationModal)){
            if(this.props.showLocationModal){
                this.showAlertAnimation();
                this.addressInputChangeHandler(this.state.submittedInputVal);
            } else {
                this.hideAlertAnimation();
            }
        }

        if(prevProps.branchData !== this.props.branchData){
            if(this.props.branchData){
                this.setState({
                    inputVal: this.props.branchData.address,
                    mapMarker: {
                        x: this.props.branchData.latitude,
                        y: this.props.branchData.longitude,
                        centerX: this.props.branchData.latitude,
                        centerY: this.props.branchData.longitude,
                    }
                })
            } else {
                this.setState({
                    inputVal: '',
                    mapMarker: {
                        x: null,
                        y: null,
                    }
                })
            }
        }

        if(this.state.submittedInputVal !== prevState.submittedInputVal){
            console.log(this.state.submittedInputVal);
        }

        if(this.addressSearchBtnRef.current){
            if((this.state.addressFocused || this.state.inputVal) && !this.state.submittedInputVal){
               this.addressTween = gsap.fromTo(this.addressSearchBtnRef.current, {scaleX: 0.8, scaleY: 0.8}, {scaleX: 1, scaleY: 1, duration: 1,  repeat: -1, yoyo:true});
            } else {
                if(this.addressTween) {
                    gsap.killTweensOf(this.addressSearchBtnRef.current);
                }
                gsap.to(this.addressSearchBtnRef.current, {scaleX: 1, scaleY: 1});
            }
        }

    }

    hideAlertAnimation = (duration = 0.3) => {
        gsap.to(this.modalRef.current, {
            opacity: 0,
            display: 'none',
            duration: duration,
        });
        document.querySelector('body, html').style.overflowY = 'auto';
    }

    showAlertAnimation = (duration = 0.3) => {
        gsap.to(this.modalRef.current, {
            opacity: 1,
            display: 'block',
            duration: duration,
        });
        document.querySelector('body, html').style.overflowY = 'hidden';
    }

    hideModalHandler = () => {
        this.props.hideModal();
    }

    addressInputChangeHandler = (val, setAddress = true) => {
        if(setAddress){
            this.setState({
                inputVal: val
            })
        }
    }

    setMapMarker = (lat, ln, setCenter = true, setMarker = true) => {
        let centerCoords = {};
        if(setCenter){
            centerCoords = {
                centerX: lat,
                centerY: ln,
            }
        }

        if(setMarker){
            this.setState({
                mapMarker: {
                    x: lat,
                    y: ln,
                    ...centerCoords,
                }
            })
        }
    }

    chooseUserBranchHelper = (curBranchId) => {
        this.props.chooseUserBranch(
            this.state.inputVal,
            this.state.mapMarker.x,
            this.state.mapMarker.y,
            curBranchId,
        )
            .then(resStatus => {
                if(resStatus === 0){
                    this.hideModalHandler();
                }
            })
    }

    pinLocationChanged = () => {
        return this.state.mapMarker.x !== this.props.branchData.latitude || this.state.mapMarker.y !== this.props.branchData.longitude
    }

    chooseUserBranchHandler = () => {
        if(this.state.submittedInputVal && this.state.mapMarker.x && this.state.mapMarker.y) {
            if(this.props.branchData && (this.props.branchData.address !== this.state.submittedInputVal || this.pinLocationChanged()) ){
                let curBranchId = this.props.branchData.branchid;
                this.chooseUserBranchHelper(curBranchId);
            } else if(!this.props.branchData){
                this.chooseUserBranchHelper(null);
            }
        }
    }

    submitInputValueToMaps = () => {
        this.setState((state) => ({
            submittedInputVal: state.inputVal,
        }));
    }

    setAddressValue = (value) => {
        this.setState({
            submittedInputVal: value,
            inputVal: value,
        });
    }

    onAddressFieldFocus = () => {
        this.setState({
            addressFocused: true,
        })
    }

    onAddressFieldBlur = () => {
        this.setState({
            addressFocused: false,
        })
    }

    render() {

        const renderCartWarning = () => {
            if(this.props.showModalCartWarning){
                return (
                    <p className={'text-danger'}>{this.props.getStr('branch_modal_cart_warning') || 'კალათა შეიძლება წაიშალოს ჯიგარო'}</p>
                )
            }
            return null;
        }

        return (
            <div ref={this.modalRef} className={s.wrp}>
                <div className={s.overlay} onClick={this.hideModalHandler} />
                <div className={s.modalScrollWrp}>
                    <div className={s.modalWrp}>
                        <div className={`${s.modal}`}>

                            <div className={s.closeBtn} onClick={this.hideModalHandler}>
                                <i className="fas fa-times"></i>
                            </div>

                            <div className={'d-flex flex-column h-100'}>

                                <div className={'mb-3'}>
                                    <h3>{this.props.getStr('branch_modal_title')} </h3>
                                    <p className={'text-secondary'}>{this.props.getStr('branch_modal_small')}</p>
                                    { this.props.branchError && <p className={'text-danger'}>{this.props.branchError}</p> }
                                    {renderCartWarning()}
                                </div>

                                <div className={'h-100 d-flex flex-column'}>
                                    <div className={'mb-3'}>
                                        <label className={'mb-2 font-weight-bold'} htmlFor="modal-address">{this.props.getStr('address_field')}</label>
                                        <div className={'d-flex align-items-stretch'}>
                                            <input
                                                type="text"
                                                id={'modal-address'}
                                                className={'form-control h-auto w-auto flex-grow-1'}
                                                value={this.state.inputVal}
                                                onChange={(e) => this.addressInputChangeHandler(e.target.value)}
                                                onFocus={this.onAddressFieldFocus}
                                                onBlur={this.onAddressFieldBlur}
                                            />
                                            <BaseBtn
                                                propRef={this.addressSearchBtnRef}
                                                btnType={'Gray'}
                                                text={this.props.getStr('branch_modal_search') || 'search'}
                                                className={'d-block py-2'}
                                                callback={this.submitInputValueToMaps}
                                            />
                                        </div>
                                    </div>

                                    <div className={`${s.mapWrp} d-flex flex-grow-1`}>
                                        <CheckoutMap
                                            mapMarker={this.state.mapMarker}
                                            setMapMarker={this.setMapMarker}
                                            addressValue={this.state.submittedInputVal}
                                            setAddressValue={this.setAddressValue}
                                        />
                                    </div>

                                    <div className={'mt-3'}>
                                        <BaseBtn
                                            callback={this.chooseUserBranchHandler}
                                            btnType={'Yellow'}
                                            text={this.props.getStr('branch_modal_finish')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    showLocationModal: state.app.showLocationModal,
    branchData: state.app.branchData,
    branchError: state.app.branchError,
    showModalCartWarning: state.app.showModalCartWarning,
})

const mapDispatchToProps = (dispatch) => ({
    hideModal: () => dispatch(hideLocationModal()),
    chooseUserBranch: (address, latitude, longitude, curBranchId) => dispatch(chooseUserBranch(address, latitude, longitude, curBranchId))
})

export default connect(mapStateToProps, mapDispatchToProps)(
    withStr(LocationModal)
);