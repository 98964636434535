import React from "react";
import {Link} from "react-router-dom";
import YellowWhiteBtn from "./YellowWhiteBtn/YellowWhiteBtn";
import YellowBtn from "./YellowBtn/YellowBtn";
import GrayBtn from "./GrayBtn/GrayBtn";
import s from "./BaseBtn.module.css";
import WhiteBtn from "./WhiteBtn/WhiteBtn";


const BaseBtn = props => {

    let sizeClass = '';
    switch (props.size) {
        case 'md':
            sizeClass = s.md;
            break;
        case 'lg':
            sizeClass = s.lg;
            break;
        default:
            sizeClass = s.md;
            break;
    }

    let BtnComp = null;
    switch (props.btnType) {
        case 'YellowWhite':
            BtnComp = YellowWhiteBtn;
            break;
        case 'Yellow':
            BtnComp = YellowBtn;
            break;
        case 'Gray':
            BtnComp = GrayBtn;
            break;
        case 'White':
            BtnComp = WhiteBtn;
            break;
        default :
            BtnComp = WhiteBtn;
            break;
    }

    const btnRender = () => {
        return (
            <BtnComp
                propRef={props.propRef}

                type={props.type ?? null}
                onBtnClicked={props.callback}
                baseClass={`${s.btn} ${props.className}`}
                sizeClass={sizeClass}
                text={props.text}

                active={props.active}
                activeClass={s.active}
            />
        )
    }
    const btnLinkRender = () => {
        if(props.linkPath){
            return <Link to={props.linkPath}>{btnRender()}</Link>
        } else {
            return btnRender();
        }
    }

    return (
        btnLinkRender()
    )

}

export default BaseBtn;