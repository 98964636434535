import React, {Component} from "react";
import LogoUrl from '../../../assets/images/tsiskhvili-logo.png';
import s from './MobileNavbar.module.css';
import MobileNavbarSidebar from "./MobileNavbarSidebar";
import {Link} from "react-router-dom";
import {linkWithLang} from "../../../helpers/helpers";

class DesktopNavbar extends Component {
    render(){

        const renderMessage = () => {
            if(!this.props.branchData){
                return (
                    <div className={'position-relative w-100'}>
                        <i className={`fas fa-sort-up ${s.locationMessageArrow}`}></i>
                        <div className={s.locationMessageWrp}>
                            <div className={s.locationMessage}>{this.props.getStr('branch_modal_title')}</div>
                        </div>
                    </div>
                )
            }
            return null;
        }

        return (
            <div className={s.mobileNavbar}>

                <MobileNavbarSidebar
                    pageNames={this.props.pageNames}
                    onSidebarCloseBtnClicked={this.props.onSidebarCloseBtnClicked}
                    showSidebar={this.props.showSidebar}
                    getStr={this.props.getStr}
                    languages={this.props.languages}
                    langId={this.props.langId}
                    chooseLangHandler={this.props.chooseLangHandler}
                />

                <div className={'position-relative w-100'}>

                    <div className={s.logoWrp}>
                        <Link to={linkWithLang('/')} className={'d-inline-block'}>
                            <img src={LogoUrl} className={s.logo} alt=""/>
                        </Link>
                    </div>

                    {/*burger icon*/}
                    <div onClick={this.props.onSidebarBtnClicked} className={`d-flex justify-content-start h-100 ${s.burgerWrp}`}>
                        <div className={'d-flex align-items-center'}>
                            <i className={`fas fa-bars ${s.burgerIcon}`}></i>
                        </div>
                    </div>
                    {/*/.*/}

                    {/*cart*/}
                    <div  className={`d-flex justify-content-end h-100 ${s.cartWrp}`}>
                        <div className={'d-flex align-items-center'}>

                            <div className={s.cartIconWrp} onClick={this.props.onCartBtnClicked}>
                                <div className={`d-flex align-items-center justify-content-center ${s.cartAmount}`}>{this.props.cartItemsAmount}</div>
                                <i className={`fas fa-shopping-cart ${s.cartIcon}`}></i>
                            </div>


                            <div className={`ml-2 ${s.locationIcon}`} onClick={this.props.showLocationModal}>
                                <i className="fas fa-map-marker-alt"></i>
                                {renderMessage()}
                            </div>

                        </div>
                    </div>
                    {/*/.*/}

                </div>
            </div>
        )
    }
}

export default DesktopNavbar;