import React, {Component} from "react";
import s from './FeaturedProducts.module.css';
import {Container} from "react-bootstrap";
import Products from "../../Products/Products";
import BaseBtn from "../../common/NormalBtns/BaseBtn";
import {linkWithLang} from "../../../helpers/helpers";


class FeaturedProducts extends Component {

    render() {

        return (
            <div className={s.wrp}>
                <Container>
                    <h1 className={s.title}>{this.props.getStr('featured_products')}</h1>
                    <Products
                        className={'mb-4'}
                        boxShadow={true}
                        addCartItem={this.props.addCartItem}
                        onRenderPopup={this.props.onRenderPopup}
                        productsArr={this.props.featuredProducts}/>



                </Container>

                <div className={'text-center mt-3'}>
                    <BaseBtn
                        className={'px-5'}
                        btnType={'Gray'}
                        text={this.props.getStr('all_products')}
                        linkPath={linkWithLang('/shop')}
                        size={'md'}/>
                </div>
            </div>
        )
    }
}

export default FeaturedProducts;