import React, {Component} from "react";
import s from './Blog.module.css';
import bannerPlaceholder from "../../assets/images/banner-placeholder.jpg";
import TitleBanner from "../common/TitleBanner/TitleBanner";
import BlogItems from "../BlogItems/BlogItems";
import {Container} from "react-bootstrap";
import {connect} from "react-redux";
import {fetchBlogPosts} from "../../store/reducers/blogPageReducer";
import Loader from "../common/Loader/Loader";
import withStr from "../../hoc/withStr";
import {imagePrepath, pageImageNames} from "../../helpers/helpers";

class Blog extends Component{
   componentDidMount() {
      this.props.fetchBlogPosts();
      window.scroll(0,0);
   }

   render(){

      if(!this.props.blogPosts){
         return <Loader />
      }

      const imageName = this.props.general[pageImageNames.blog];
      const bannerImagePath = imageName ? imagePrepath(imageName) : bannerPlaceholder;

      return (
         <div>
            <TitleBanner imageURL={bannerImagePath} text={this.props.getStr('blog')} />
            <div className={s.wrp}>
               <Container>
                  <BlogItems itemsArr={this.props.blogPosts} />
               </Container>
            </div>
         </div>
      )
   }
}

const mapStateToProps = state => ({
   blogPosts: state.blogPage.blog,
   general: state.app.generalData,
});

const mapDispatchToProps = dispatch => ({
   fetchBlogPosts: () => dispatch(fetchBlogPosts())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStr(Blog));