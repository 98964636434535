//action constants
import apiHandler from "../../api/api";

const INIT_HOMEPAGE = 'INIT_HOMEPAGE';
const SET_BRANCHES = 'SET_BRANCHES';

//initial state
const initState = {
    featuredProducts: [],
    categories: [],
    specialOffers: [],
    slides: [],
    blog: [],
    gallery: [],
    branches: [],
    init: false,
}

//reducer
const homePageReducer = (state = initState, action) => {
    switch (action.type) {
        case INIT_HOMEPAGE:
            return {
                ...state,
                featuredProducts: action.featuredProducts,
                categories: action.categories,
                specialOffers: action.specialOffers,
                slides: action.slides,
                blog: action.blog,
                gallery: action.gallery,
                branches: action.branches,
                init: true,
            }
        case SET_BRANCHES:
            return {
                ...state,
                branches: action.branches
            }
        default:
            return state;
    }
}

export default homePageReducer;


//action creators
// export const follow = (userId) => ({type: FOLLOW, userId: userId});


//thunk creators
export const initHomepage = () => {
    return dispatch => {
        return Promise.all([
                apiHandler('getCategories'),
                apiHandler('getFeaturedProducts'),
                apiHandler('getSliderItems'),
                apiHandler('getSpecialOffers'),
                apiHandler('getBlogPosts'),
                apiHandler('getGalleryItems'),
                apiHandler('getBranches'),
            ]
        )
            .then(res => {
                const dataObj = {
                    categories: res[0],
                    featuredProducts: res[1],
                    slides: res[2],
                    specialOffers: res[3],
                    blog: res[4],
                    gallery: res[5],
                    branches: res[6],
                }

                dispatch({type: INIT_HOMEPAGE, ...dataObj });



                // console.log(res);
            })
    }
}


export const getBranches = () => {
    return dispatch => {
        apiHandler('getBranches')
            .then(res => {
                dispatch({type: SET_BRANCHES, branches: res})
            })
    }
}

