import React, {Component} from "react";
import DesktopNavbar from "./DesktopNavbar/DesktopNavbar";
import MobileNavbar from "./MobileNavbar/MobileNavbar";
import Cart from "./Cart/Cart";
import {connect} from "react-redux";
import {getCart, removeCartItem} from "../../store/reducers/cartReducer";
import withPopup from "../../hoc/withPopup/withPopup";
import {getDynamicPages} from "../../store/reducers/dynamicPageReducer";
import withStr from "../../hoc/withStr";
import {showLocationModal} from "../../store/reducers/appReducer";

class Navbar extends Component {

    state={
        showSidebar: false,
        showCart: false,
    }

    onSidebarBtnClicked = () => {
        this.setState({
            showSidebar: true,
        });
    }

    onSidebarCloseBtnClicked = () => {
        this.setState({
            showSidebar: false,
        });
    }

    onCartBtnClicked = () => {
        if(this.props.branchData){
            this.setState({
                showCart: true,
            });
        }
    }

    onCartCloseBtnClicked = () => {
        this.setState({
            showCart: false,
        });
    }

    componentDidMount() {
        this.props.getCart();
        this.props.getPageNames();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('Cart Data', this.props.cartData);
    }

    render(){
        let cartItemsAmount = 0;
        if(this.props.cartData){
            cartItemsAmount = this.props.cartData.reduce((accum, cur) => {
                return accum + cur.amount;
            }, 0);
        }

        return (
            <>
                <DesktopNavbar
                   newLangUrl={this.props.newLangUrl}
                   cartItemsAmount={cartItemsAmount}
                   onCartBtnClicked={this.onCartBtnClicked}
                   pageNames={this.props.pageNames}
                   getStr={this.props.getStr}
                   generalData={this.props.generalData}

                   languages={this.props.languages}
                   langId={this.props.langId}
                   chooseLangHandler={this.props.chooseLangHandler}

                   showLocationModal={this.props.showLocationModal}
                   branchData={this.props.branchData}
                />

                <MobileNavbar
                    cartItemsAmount={cartItemsAmount}
                    showSidebar={this.state.showSidebar}
                    onSidebarCloseBtnClicked={this.onSidebarCloseBtnClicked}
                    onSidebarBtnClicked={this.onSidebarBtnClicked}
                    onCartBtnClicked={this.onCartBtnClicked}
                    pageNames={this.props.pageNames}

                    getStr={this.props.getStr}
                    languages={this.props.languages}
                    langId={this.props.langId}
                    chooseLangHandler={this.props.chooseLangHandler}

                    showLocationModal={this.props.showLocationModal}
                    branchData={this.props.branchData}
                />

                <Cart
                    cartData={this.props.cartData}
                    showCart={this.state.showCart}
                    onCartItemRemoved={this.props.removeCartItem}
                    onEditCartItem={this.props.onEditCartItemHandler}
                    onRenderPopup={this.props.onRenderPopupHandler}
                    onCartCloseBtnClicked={this.onCartCloseBtnClicked}
                    getStr={this.props.getStr}
                />
            </>
        )
    }
}

const mapStateToProps = state => ({
    cartData: state.cart.cart,
    pageNames: state.dynamicPage.pageNames,
    generalData: state.app.generalData,
    branchData: state.app.branchData,
});

const mapDispatchToProps = dispatch => ({
    getCart: () => dispatch(getCart()),
    getPageNames: () => dispatch(getDynamicPages()),
    showLocationModal: () => dispatch(showLocationModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStr(withPopup(Navbar)));