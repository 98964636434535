import React from "react";
import {Col, Container, Dropdown, Row} from 'react-bootstrap';
import s from './DesktopNavbar.module.css';
import BaseBtn from "../../common/NormalBtns/BaseBtn";
import {Link} from "react-router-dom";
import {getLogoAddress, linkWithLang} from "../../../helpers/helpers";

const DesktopNavbar = (props) => {

    const chosenLanguage = props.languages.find(el => el.id === props.langId).name;
    const otherLanguages = props.languages.map((el, i) => {
        if(el.id !== props.langId && el.enabled === 1) {
            return <div key={i} className={'dropdown-item'} onClick={() => props.chooseLangHandler(el.id)}>{el.name}</div>
        }
        return null;
    })

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div  className={`${s.LanguageDropdownToggle} headline-font`}
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </div>
    ));

    const renderMessage = () => {
        if(!props.branchData){
            return (
                <div className={'position-relative w-100'}>
                    <i className={`fas fa-sort-up ${s.locationMessageArrow}`}></i>
                    <div className={s.locationMessageWrp}>
                        <div className={s.locationMessage}>{props.getStr('branch_modal_title')}</div>
                    </div>
                </div>
            )
        }
        return null;
    }

    return (
        <div className={s.mainWrp}>
            <div className={s.desktopNavbar}>
                <Container>
                    <Row className={'justify-content-center'}>
                        <Col lg={12}>
                            <Row className={'justify-content-between'}>


                                <div className={s.LogoWrp}>
                                    <Col>
                                        <div className={s.LogoInnerWrp}>
                                            <Link to={linkWithLang('/')}>
                                                <img src={getLogoAddress(props.generalData)} className={s.Logo} alt="logo"/>
                                            </Link>
                                        </div>
                                    </Col>
                                </div>

                                <Col md={7}>
                                    <div className={'d-flex align-items-center'}>
                                        <nav className={s.navItemsOuterWrp}>
                                            <ul className={s.navItemsInnerWrp}>


                                                <li className={`${s.navItem} font-weight-bold headline-font`}>
                                                    <Link to={linkWithLang('/')}>{props.getStr('nav_home')}</Link>
                                                </li>

                                                <Link to={linkWithLang('/categories')} className={`${s.navItem}`}>
                                                    <BaseBtn
                                                        className={'font-weight-bold headline-font btn-border-rounded px-4'}
                                                        btnType={'Yellow'}
                                                        text={props.getStr('nav_shop')}
                                                    />
                                                </Link>

                                                <li className={`${s.navItem} font-weight-bold headline-font`}>
                                                    <Link to={linkWithLang('/blog')}>{props.getStr('nav_blog')}</Link>
                                                </li>

                                                <li className={`${s.navItem} font-weight-bold headline-font`}>
                                                    <Link to={linkWithLang('/career')}>{props.getStr('nav_career')}</Link>
                                                </li>

                                                {props.pageNames.map((el, i) => {
                                                    return (
                                                        <li key={i} className={`${s.navItem} font-weight-bold headline-font`}>
                                                            <Link to={linkWithLang(`/pages/${el.path}`)}>{el.name}</Link>
                                                        </li>
                                                    )
                                                })}

                                                <li className={`${s.navItem} font-weight-bold headline-font`}>
                                                    <Link to={linkWithLang('/contact')}>{props.getStr('nav_contact')}</Link>
                                                </li>




                                            </ul>
                                        </nav>
                                    </div>
                                </Col>

                                <Col md={2}>
                                    <div className={`d-flex justify-content-end align-items-center h-100 ${s.cartWrp}`}>

                                        <Dropdown className={'mr-3'}>
                                            <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                                                {chosenLanguage}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {otherLanguages}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/*<Link className={`${s.langToggle}`} to={props.newLangUrl}/>*/}

                                        <div className={`d-flex align-items-center ${s.cartIconWrp}`}
                                             onClick={props.branchData ? props.onCartBtnClicked : props.showLocationModal}>
                                            <div className={'position-relative'}>
                                                <div
                                                    className={`d-flex align-items-center justify-content-center ${s.cartAmount}`}>
                                                    {props.cartItemsAmount}
                                                </div>
                                                <i className={`fas fa-shopping-cart ${s.cartIcon}`}></i>
                                            </div>
                                        </div>

                                        <div>
                                            <div className={`ml-2 ${s.locationIcon}`} onClick={props.showLocationModal}>
                                                <i className="fas fa-map-marker-alt"></i>
                                            </div>

                                            {renderMessage()}

                                        </div>

                                    </div>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div onClick={props.onCartBtnClicked} className={`${s.cartFixedWrp} d-none`}>
                <div className={`${s.cartFixedInnerWrp}`}>
                    <div
                        className={`d-flex align-items-center justify-content-center ${s.cartAmount}`}>{props.cartItemsAmount}</div>
                    <i className={`fas fa-shopping-cart ${s.cartIcon}`}></i>
                </div>
            </div>



        </div>
    )
}

export default DesktopNavbar;