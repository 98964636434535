//action constants
import apiHandler from "../../api/api";

const CLOSE_POPUP = 'CLOSE_POPUP';
const SHOW_POPUP = 'SHOW_POPUP';
const SET_OPTIONS = 'SET_OPTIONS';
const ADD_INGREDIENT = 'ADD_INGREDIENT';
const REMOVE_INGREDIENT = 'REMOVE_INGREDIENT';
const INCREMENT_AMOUNT = 'INCREMENT_AMOUNT';
const DECREMENT_AMOUNT = 'DECREMENT_AMOUNT';
const SET_SELECTED_OPTIONS = 'SET_SELECTED_OPTIONS';


//initial state
const initState = {
    id: null,
    // showPopup: false,
    options: null,
    /*
    options DOCUMENTATION
    default state on first load -> NULL
    --------------------------------------------------
    #single product Options ->
    options: {
        singleProdOptions: [] // option ids from selected options
    }
    --------------------------------------------------
    #Collection Options ->
    options: {
        children [
            {
                id(product id): x,
                options: [{optId: x, packId: x}, ...] // option ids from selected options
            },
            ...
        ]
    }
    --------------------------------------------------
     */
    selectedOptions: null,
    /*
    selectedOptions DOCUMENTATION
    default state on first load -> NULL
    --------------------------------------------------
    #single product Options ->
    {
        options: [{optId: x, packId: x}, ...] // option ids from selected options
    }
    --------------------------------------------------
    #Collection Options ->
    {
        children [
            {
                id(product id): x,
                options: [{optId: x, packId: x}, ...] // option ids from selected options
            },
            ...
        ]
    }
    --------------------------------------------------
     */
    amount: 1,
    price: null,
    discounted_price: null,
    name: '',
    desc: '',
    product_image: null,
    editMode: false,
}

//reducer
const popupReducer = (state = initState, action) => {
    switch (action.type) {
        case CLOSE_POPUP:
            return {
                ...state,
                id: null,
                name: '',
                desc: '',
                price: null,
                discounted_price: null,
                image_link: null,

                options: null,
                selectedOptions: null,
                editMode: false,
                index: undefined,
            };
            break;
        case SHOW_POPUP:
            return {
                ...state,
                id: action.id,
                name: action.name,
                desc: action.desc,
                price: action.price,
                discounted_price: action.discounted_price,
                image_link: action.image_link,

                amount: action.amount,
                editMode: action.editMode,
                product_image: action.product_image,
                index: action.index,
            }
            break;
        case SET_OPTIONS:
            return {
                ...state,
                options: action.options,
            }
            break;
        case SET_SELECTED_OPTIONS:
            return {
                ...state,
                selectedOptions: action.selectedOptions,
            }
            break;
        case ADD_INGREDIENT:
            //generate new array based on type: 0 - radio, 1-checkbox
            //key reason for this is that when we select different radio others have to be deselected on fake checkbox and from state
            const getNewSelectedOptionsObj = () => {
                let selectedOptionsObj = {};

                if(state.selectedOptions.hasOwnProperty('options')){
                    // this code executes when we handle singleProduct's options
                    selectedOptionsObj.options = state.selectedOptions.options.filter(opt => {
                        if (action.dict.type === 0) {
                            return opt.optId !== action.dict.optObj.optId;
                        }
                        return true;
                    });
                    selectedOptionsObj.options.push(action.dict.optObj);

                } else if(state.selectedOptions.hasOwnProperty('children')){
                    // this code executes when we handle collection's options

                    // copying selectedOptions -> children with first level immutability
                    selectedOptionsObj.children = [...state.selectedOptions.children];
                    // storing necessary child object whose option was selected, we identify it using childIndex
                    const selectedOptionsChild = state.selectedOptions.children[action.dict.childIndex];

                    // filter customizables of this child
                    ;
                    selectedOptionsObj.children[action.dict.childIndex].customizables = selectedOptionsChild.customizables.filter(opt => {
                        if (action.dict.type === 0) {
                            return opt.optId !== action.dict.optObj.optId;
                        }
                        return true;
                    });

                    selectedOptionsObj.children[action.dict.childIndex].customizables.push({
                        optId: action.dict.optObj.optId,
                        packId: action.dict.optObj.packId,
                    })

                }

                return selectedOptionsObj;
            }
            // /.

            return {
                ...state,
                selectedOptions: getNewSelectedOptionsObj(),
            };
            break;
        case REMOVE_INGREDIENT:
            if(state.selectedOptions.hasOwnProperty('options')){
                return {
                    ...state,
                    selectedOptions: {
                        options: state.selectedOptions.options.filter(opt => {
                            // filter returns array elements whose optId and packId don't match
                            return !(opt.optId === action.dict.optObj.optId && opt.packId === action.dict.optObj.packId)
                        }),
                    }
                };
            } else if (state.selectedOptions.hasOwnProperty('children')){
                return {
                    ...state,
                    selectedOptions: {
                        children: state.selectedOptions.children.map((child) => {
                            return {
                                ...child,
                                customizables: child.customizables.filter(opt => {
                                    // filter returns array elements whose optId and packId don't match
                                    return !(opt.optId === action.dict.optObj.optId && opt.packId === action.dict.optObj.packId)
                                }),
                            }
                        }),
                    }
                };
            }
            break;
        case INCREMENT_AMOUNT:
            return {
                ...state,
                amount: state.amount + 1
            }
            break;
        case DECREMENT_AMOUNT:
            return {
                ...state,
                amount: state.amount - 1 !== 0 ? state.amount - 1 : 1,
            }
            break;
        default:
            return state;
    }
}

export default popupReducer;


//action creators
export const closePopup = () => {
    return {type: CLOSE_POPUP};
}

const showPopup = (dataObj) => {
    return {type: SHOW_POPUP, ...dataObj};
}

const setOptions = (optionsObj) => {
    return {type: SET_OPTIONS, options: optionsObj}
}

const copyCustomizables = (customizables) => {
    return customizables.map(el => {
        return {
            ...el,
            packs: el.packs.map(el => {
                return {
                    ...el
                }
            })
        }
    })
}

const generateCollectionOptions = (children, customizablesFunc) => {
    const newArr = [];
    children.forEach(el => {
        for(let x = 0; x < el.amount; x++){
            newArr.push({
                id: el.id,
                name: el.name + ` #${x+1}`,
                customizables: customizablesFunc(el.customizables)
            });
        }
    });

    return newArr;
}

const generateSelectedOptionsCollection = (children) => {
    const newArr = [];
    // childIndex is needed so that we know which child had it's options changed
    let childIndex = 0;

    children.forEach((el, index) => {
        for(let x = 0; x < el.amount; x++){
            newArr.push({
                id: el.id,
                // index: childIndex,
                customizables: [],
            });
            childIndex++;
        }
    });

    return newArr;
}

const renderPopupInner = (
    prod_id,
    name,
    desc,
    price,
    selectedOptions = null,
    amount = 1,
    editMode = false,
    index = undefined,
    dispatch,
    image_link,
    discounted_price
) => {

    const getProductPromise = apiHandler('getProduct', {Productid: prod_id});
    const getCustomizablesPromise = apiHandler('getCustomizables', {Productid: prod_id});

    Promise.all([getProductPromise, getCustomizablesPromise])
        .then(res => {
            console.log(res);
            // setting product general data
            const productData = res[0];
            const showPopupObj = {
                id: productData.id,
                name: productData.name,
                desc: productData.description,
                price: productData.price,
                amount: productData.amount || amount,
                editMode: editMode,
                index: index,
                image_link: productData.image_name,
                discounted_price: productData.discounted_price
            }
            dispatch(showPopup(showPopupObj));

            //setting product customization data/ if a collection then setting collection's products and customization for those products
            const customData = res[1];
            if(productData.type === 1 && customData.hasOwnProperty('children')){
                // this if statement executes when this is a collection

                dispatch(setOptions({
                    children: generateCollectionOptions(customData.children, copyCustomizables),
                }));


                if(!selectedOptions){
                    // no selected options
                    dispatch(setSelectedOptions({
                        children: generateSelectedOptionsCollection(customData.children)
                    }))
                } else {
                    // when we have selected options
                    dispatch(setSelectedOptions({
                        children: selectedOptions.children
                    }))
                }


            } else {

                // this is just a product
                dispatch(setOptions({
                    singleProdOptions: customData.customizables,
                }));

                dispatch(setSelectedOptions({
                    options: selectedOptions ? selectedOptions.options : []
                }))
            }
        });



    // apiHandler('getCustomizables', {Productid: prod_id})
    //     .then(res => {
    //         dispatch(setOptions(res.customizables));
    //         // ;
    //         dispatch(setSelectedOptions(selectedOptions))
    //     });
}

export const renderPopup = (
    prod_id,
    name,
    desc,
    price,
    image_link,
    selectedOptions,
    amount,
    editMode,
    index
) => {
    return (dispatch) => {
        renderPopupInner(
            prod_id,
            name,
            desc,
            price,
            selectedOptions,
            amount,
            editMode,
            index,
            dispatch,
            image_link,
        );
    }
}

const setSelectedOptions = (setSelectedOptions) => {
    return {type: SET_SELECTED_OPTIONS, selectedOptions: setSelectedOptions}
}

export const onAddIngredient = (dict) => {
    return {type: ADD_INGREDIENT, dict}
}

export const onRemoveIngredient = (dict) => {
    return {type: REMOVE_INGREDIENT, dict}
}

export const incrementAmount = () => {
    return {type: INCREMENT_AMOUNT}
}

export const decrementAmount = () => {
    return {type: DECREMENT_AMOUNT}
}

const reverseTransformSelectedOptions = (cartItem) => {
    const optionsObj = {};

    if(cartItem.hasOwnProperty('customizables')){
        // this is a single product's customizables
        optionsObj.options = [];
        cartItem.customizables.forEach((el) => {
            el.packs.forEach((pack) => {
                optionsObj.options.push({
                    optId: el.id,
                    packId: pack.id,
                })
            })
        });
    }
    else if(cartItem.hasOwnProperty('children'))
    {
        // this is a collection's customizables
        optionsObj.children = [];

        cartItem.children.forEach((child) => {
            const curChildCustomizablesArr = [];
            child.customizables.forEach((el) => {
                el.packs.forEach((pack) => {
                    curChildCustomizablesArr.push({
                        optId: el.id,
                        packId: pack.id,
                    })
                })
            });

            optionsObj.children.push({
                id: child.id,
                amount: 1,
                customizables: curChildCustomizablesArr,
            })
        })

    }


    ;

    return optionsObj;
}

export const editCartItemPopup = (index, amount, selectedOptions, id, callback = undefined) => {
    return dispatch => {

        const newSelectedOptions = reverseTransformSelectedOptions(selectedOptions);
    debugger;

        apiHandler('getProduct', {Productid: id})
            .then(res => {
                //
                renderPopupInner(
                    res.id,
                    res.name,
                    res.description,
                    res.price,
                    newSelectedOptions,
                    amount,
                    true,
                    index,
                    dispatch,
                    res.image_name,
                    res.discounted_price
                )

                if (callback !== undefined) {
                    callback()
                }
            })
    }
}