import React, {Component, createRef} from 'react';
import s from './PhoneValidator.module.css';
import gsap from 'gsap';
import BaseBtn from "../common/NormalBtns/BaseBtn";

class PhoneValidator extends Component {
   popupWrpRef = createRef();
   popupBoxRef = createRef();

   state={
      verificationCode: '',
   }

   componentDidMount(){
      this.initPopup();
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevProps.showPhoneValidatorPopup !== this.props.showPhoneValidatorPopup){
         if(this.props.showPhoneValidatorPopup){
            this.showPopupAnimation();
            console.log('ddd');
            this.setState({
               verificationCode: '',
            });
         } else {
            this.closePopupAnimation();
         }
      }
   }

   onVerificationCodeChanged = (e) => {
      this.setState({
         verificationCode: e.target.value,
      })
   }


   // popup show/hide methods
   // popup SHOW/HIDE animation methods
   initPopup = () => {
      this.props.onClosePopup();
      //init styles for popup reveal animation
      const popupWrpRef = this.popupWrpRef.current;
      const popupBoxRef = this.popupBoxRef.current;

      gsap.set(popupWrpRef, {display: 'none', opacity: 0});
      gsap.set(popupBoxRef, {opacity: 0, scaleX: 0.8, scaleY: 0.8, x: '-50%', y: '-50%'});
   }

   showPopupAnimation = () => {
      const popupWrpRef = this.popupWrpRef.current;
      const popupBoxRef = this.popupBoxRef.current;

      //animation wrapper
      gsap.set(popupWrpRef, {display: 'block'});
      gsap.to(popupWrpRef, {opacity: 1, duration: 0.2});

      //animation popupbox
      gsap.to(popupBoxRef, {opacity: 1, scaleX: 1, scaleY: 1, duration: 0.2});
   }

   closePopupAnimation = () => {
      const popupWrpRef = this.popupWrpRef.current;
      const popupBoxRef = this.popupBoxRef.current;

      //animation wrapper
      gsap.to(popupWrpRef, {opacity: 0, duration: 0.2, onComplete: () => {
            gsap.set(popupWrpRef, {display: 'none'});
         }
      });

      //animation popupbox
      gsap.to(popupBoxRef, {opacity: 0, scaleX: 0.8, scaleY: 0.8, duration: 0.2});
   }
   ///.

   render() {
      return (
         <div className={s.wrp} ref={this.popupWrpRef}>
            <div className={s.darkBg} onClick={this.props.onClosePopup}></div>
            <div className={s.box} ref={this.popupBoxRef}>
               <form onSubmit={(e) => {
                  e.preventDefault();
                  this.props.onSubmitHandler(this.state.verificationCode);
               }}>
                  <h3 className={`mb-2 ${s.title}`}><label htmlFor="">{this.props.getStr('verif_title')}</label></h3>
                  <p className={`mb-3 d-block ${s.par}`}>{this.props.getStr('verif_code_text')}</p>
                  <input
                     type="text"
                     className={'form-control'}
                     value={this.state.verificationCode}
                     onChange={this.onVerificationCodeChanged}/>
                  <div className={'mt-3'}>
                     <BaseBtn
                        btnType={'Yellow'}
                        text={this.props.getStr('submit')}
                        size={'md'}
                     />
                  </div>
               </form>
            </div>
         </div>
      );
   }
}

export default PhoneValidator;