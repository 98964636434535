import React, {Component, createRef} from 'react';
import Swiper from "swiper";
import s from "./Locations.module.css";
import {Container} from "react-bootstrap";
import {imagePrepath} from "../../../helpers/helpers";

class Locations extends Component {
    swiperRef = createRef();

    state = {
        activeIndex: 0,
    }

    swiperPrevBtn = createRef();
    swiperNextBtn = createRef();

    componentWillUnmount() {

        this.setState({
            activeIndex: 0,
        });

        if(this.props.branches.length > 0 && this.swiperInstance){
            try{
                this.swiperInstance.destroy();
            } catch (err) {
                console.log(err);
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.branches !== this.props.branches && this.props.branches.length > 1){
            this.swiperInstance = new Swiper(this.swiperRef.current, {
                // Default parameters
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },

                navigation: {
                    nextEl: this.swiperPrevBtn.current,
                    prevEl: this.swiperNextBtn.current,
                },

                // Responsive breakpoints
                breakpoints: {
                    1:{
                        slidesPerView: 1,
                        spaceBetween: 15
                    },
                    // when window width is >= 480px
                    700: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    // when window width is >= 640px
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 30
                    }
                }
            });

            this.swiperInstance.on('slideChange', () => {
                this.setState({
                    activeIndex: this.swiperInstance.activeIndex
                })
            })
        }
    }

    branchesToSlidesData = () => {
        return this.props.branches.map(branch => {
            return {
                id: branch.id,
                name: branch.title,
                address: branch.address,
                openTime: branch.open_time,
                closeTime: branch.close_time,
                phone: branch.phone,
                imageLink: branch.image_name,
            }
        });
    }

    render() {
        const branchesArr = this.props.branches;
        if(!Array.isArray(branchesArr) || branchesArr.length <= 1){
            return null;
        }

        const slidesArr = this.branchesToSlidesData();

        const swiperJSX = () => {
            if(slidesArr.length > 0) {
                return slidesArr.map((el, i) => (
                        <div key={el.id} className={`swiper-slide ${s.slide}`}>
                            <div className={s.imgWrp}>
                                <img src={imagePrepath(el.imageLink)} alt=""/>
                            </div>
                            <div className={s.textWrp}>
                                <h3 className={'mb-3'}>{el.name}</h3>
                                <div>
                                    <span className={'font-weight-bold mr-1'}>{this.props.getStr('address')}:</span>
                                    {el.address}
                                </div>
                                <div>
                                    <span className={'font-weight-bold mr-1'}>{this.props.getStr('work_hours') || 'სამუშაო საათები'}:</span>
                                    {el.openTime} - {el.closeTime}
                                </div>
                                <div>
                                    <span className={'font-weight-bold mr-1'}>{this.props.getStr('phone')}:</span>
                                    {el.phone}
                                </div>
                            </div>
                        </div>
                    )
                )
            }
            return null;
        }

        return (
            <div className={s.wrp}>
                <Container>
                    <h1 className={s.title}>{this.props.getStr('branch_slider_title') || 'ფილიალები'}</h1>

                    <div className={s.sliderWrp}>

                        <div className={`swiper-button-next swiper-btn`} ref={this.swiperPrevBtn}></div>

                        <div ref={this.swiperRef} className={`swiper-container ${s.swiperContainer}`}>
                            <div className="swiper-wrapper py-3">
                                {swiperJSX()}
                            </div>
                        </div>

                        <div className={`swiper-button-prev swiper-btn`} ref={this.swiperNextBtn}></div>

                    </div>

                </Container>

            </div>
        )
    }
}


export default Locations;