import React from "react";
import s from './Popup.module.css';
import Ingredients from "./Ingredients/Ingredients";
import BaseBtn from "../../components/common/NormalBtns/BaseBtn";
import Amount from "./Amount/Amount";
import IngredientsTitle from "./Ingredients/IngredientsTitle";
import Details from "./Details/Details";
import withStr from "../withStr";
import {imagePrepath, pageImageNames} from "../../helpers/helpers";
import bannerPlaceholder from "../../assets/images/banner-placeholder.jpg";

class Popup extends React.Component {
    render() {

        const imageName = this.props.general[pageImageNames.productPopup];
        const bannerImagePath = imageName ? imagePrepath(imageName) : bannerPlaceholder;

        const popupTopBar = (
            <div className={s.popupImg} style={{backgroundImage: `url(${bannerImagePath})`}}>
                <i className={`fas fa-times ${s.closeIcon}`} onClick={this.props.onClosePopup}/>
                <h3 className={s.bannerTitle}>{this.props.getStr('choose_dish')}</h3>
            </div>
        )


        const buildSingleProductCustomizables = () => {
            const optionsArray = this.props.options.singleProdOptions;
            if ((optionsArray.length !== this.props.showDropdowns.length) || (optionsArray.length === 0)) {
                return null;
            } else {
                return optionsArray.map((opt, i) => {
                    return <div key={i}>
                        <IngredientsTitle
                            text={opt.name}
                            onDropdownTitleClicked={() => this.props.onDropdownTitleClicked(i)}
                            drpodownIsVisible={this.props.showDropdowns[i]}
                        />

                        <div ref={this.props.dropdownRefs[i]}>
                            <Ingredients
                                type={opt.type}
                                optId={opt.id}
                                packs={opt.packs}
                                onIngredientClicked={this.props.onIngredientClicked}
                                options={optionsArray}
                                selectedOptions={this.props.selectedOptions}/>
                        </div>

                    </div>

                })
            }
        }

        const buildCollectionCustomizables = () => {
            const optionsArray = this.props.options.children;
            if (!optionsArray.length || (optionsArray.length === 0)) {
                return null;
            } else {
                let generatedIndex = -1;
                return optionsArray.map((child, childIndex) => {
                    const optionsMarkup = child.customizables.map((opt) => {
                        /*
                            generatedIndex tells us what is the index of the child in the collection,
                            we need this index when we need to independently choose
                            options for different children in the collection.
                            using this index we identify which dropdown is for which child
                         */
                        const localGeneratedIndex = ++generatedIndex;
                        // /.
                        return <div key={localGeneratedIndex} className={localGeneratedIndex}>
                            <IngredientsTitle
                                text={opt.name}
                                onDropdownTitleClicked={() => this.props.onDropdownTitleClicked(localGeneratedIndex)}
                                drpodownIsVisible={this.props.showDropdowns[localGeneratedIndex]}
                            />

                            <div ref={this.props.dropdownRefs[localGeneratedIndex]}>
                                <Ingredients
                                    type={opt.type}
                                    childIndex={childIndex}
                                    optId={opt.id}
                                    packs={opt.packs}
                                    onIngredientClicked={this.props.onIngredientClicked}
                                    options={child.customizables}
                                    selectedOptions={this.props.selectedOptions}/>
                            </div>
                        </div>

                    });

                    return (
                        <div className={'mb-3'}>
                            <div className={s.childText}>{child.name}</div>
                            {optionsMarkup}
                        </div>
                    )
                })
            }
        }

        const renderCustomizables = () => {
            let renderJSX = null;

            if (this.props.options) {
                if (this.props.options.hasOwnProperty('singleProdOptions')) {
                    renderJSX = buildSingleProductCustomizables();
                } else if (this.props.options.hasOwnProperty('children')) {
                    renderJSX = buildCollectionCustomizables();
                }
            }
            return renderJSX;
        }

        const popupBottom = (
            <div className={s.mainWrp}>
                {renderCustomizables()}
                <Amount
                    onDecrementAmount={this.props.onDecrementAmount}
                    amount={this.props.amount}
                    onIncrementAmount={this.props.onIncrementAmount}/>
            </div>
        )

        return (
            <div ref={this.props.popupWrpRef} className={s.wrp}>
                <div className={s.darkBg} onClick={this.props.onClosePopup}/>
                <div ref={this.props.popupBoxRef} className={s.popupWrp}>

                    {popupTopBar}
                    <Details
                        selected
                        name={this.props.name}
                        desc={this.props.desc}
                        price={this.props.price}
                        discountedPrice={this.props.discountedPrice}
                        amount={this.props.amount}
                        image_link={this.props.image_link}
                    />
                    {popupBottom}

                    <div className={s.addToCartBtnWrp}>
                        <div className={s.addToCartBtninnerWrp}>
                            <BaseBtn
                                callback={this.props.addToCartCallback}
                                className={'font-weight-bold headline-font btn-border-rounded px-4 w-100'}
                                btnType={'Yellow'}
                                text={this.props.editMode ? this.props.getStr('save_changes') : this.props.getStr('add_to_cart')}
                                size={'lg'}/>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

export default withStr(Popup);