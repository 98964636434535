import React from "react";
import s from './Footer.module.css';
import {Col, Container, Row, Form} from "react-bootstrap";

import logoUrl from '../../assets/images/tsiskhvili-logo.png';
import withStr from "../../hoc/withStr";
import BaseBtn from "../common/NormalBtns/BaseBtn";
import {imagePrepath} from "../../helpers/helpers";

const Footer = props => {
    return (
        <div className={s.wrp}>
            <Container>
                <div className={'d-flex flex-wrap align-items-center justify-content-between'}>

                    <div className={s.logo}>
                        <img src={imagePrepath(props.general.logo)}/>
                    </div>

                    <div className={`${s.contactInfo}`}>
                        <div className={s.textItemWrp}>
                            <a href={'tel:' + props.general.phone}>{props.general.phone}</a>
                        </div>

                        <div className={s.textItemWrp}>
                            <p>{props.general.address || 'მისამართი #2'}</p>
                        </div>

                        <div className={s.textItemWrp}>
                            <a href={'mailto:' + props.general.email}>{props.general.email}</a>
                        </div>
                    </div>

                    <div className={`${s.socialWrp}`}>
                        <h6>{props.getStr('social_footer')}</h6>
                        <div className={'d-flex justify-content-center'}>
                            <a href={props.general.facebook_link} target="_blank" className={s.iconWrp}>
                                <i className="fab fa-facebook-f"></i>
                            </a>

                            <a href={props.general.instagram_link} target="_blank" className={s.iconWrp}>
                                <i className="fab fa-instagram"></i>
                            </a>
                        </div>
                    </div>



                </div>
            </Container>
        </div>
    )
}

export default withStr(Footer);