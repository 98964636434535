import React, {createRef} from 'react';
import Swiper from 'swiper';
import s from './HomeSlider.module.css';

import {Container} from "react-bootstrap";
import BaseBtn from "../../common/NormalBtns/BaseBtn";
import {imagePrepath} from "../../../helpers/helpers";

class HomeSlider extends React.Component {
    swiperRef = createRef();
    state = {
        currentSlideIndex: 0,
    }

    // lifecycle methods
    componentWillUnmount() {
        this.setState({
            currentSlideIndex: 0,
        });

        if (this.props.slides.length > 0 && this.swiperInstance) {
            this.swiperInstance.destroy();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.currentSlideIndex !== prevState.currentSlideIndex) {
            const swiperInstance = this.swiperInstance;
            swiperInstance.slideTo(this.state.currentSlideIndex);
        }

        if (prevProps.slides !== this.props.slides && this.props.slides.length > 0) {
            this.swiperInstance = new Swiper(this.swiperRef.current, {
                // autoplay: {
                //     delay: 5000,
                //     disableOnInteraction: false,
                // },
            });
            const swiperInstance = this.swiperInstance;

            swiperInstance.slideTo(this.state.currentSlideIndex);
            swiperInstance.on('slideChange', () => {
                this.setCurrentSlide(swiperInstance.activeIndex);
            });
        }
    }

    // /.

    setCurrentSlide = (newSlideIndex) => {
        this.setState({
            currentSlideIndex: newSlideIndex,
        });
    }

    generateSlideUrl = (slideUrl) => {
        if (slideUrl.includes(window.location.hostname)) {
            const urlArr = slideUrl.split('?')
            const urlParams = new URLSearchParams(urlArr[1]);
            urlParams.set('lang', this.props.langId);

            return urlArr[0] + '?' + urlParams.toString();
        }

        return slideUrl;
    }

    render() {
        const renderSliderInsides = (slide) => {
            if (slide.title && slide.text) {
                return (
                    <>
                        <h1 className={s.title}>{slide.title}</h1>
                        <p className={s.text}>{slide.text}</p>
                        {
                            slide.productid
                            ? <BaseBtn
                                    className={'font-weight-bold headline-font btn-border-rounded mt-4 px-4'}
                                    btnType={'White'}
                                    size={'md'}
                                    text={this.props.getStr('order')}
                                    callback={(e) => {
                                        e.stopPropagation();
                                        this.props.onRenderPopupHanlder(slide.productid)
                                    }}
                                />
                            : null
                        }
                    </>
                )
            }
            return null;
        }

        if (!this.props.slides) {
            return null;
        }

        const slidesJSX = () => {
            return this.props.slides.map(slide => {
                if (slide.url) {
                    return (
                        <div
                            className={`swiper-slide ${s.swiperSlide}`}
                            key={slide.id}
                            style={{backgroundImage: `url(${imagePrepath(slide.image_name)})`}}
                        >
                            <a className={s.slideLink} href={this.generateSlideUrl(slide.url) || '#'} />
                            <div className={s.slideTextWrp}>
                                {renderSliderInsides(slide)}
                            </div>
                        </div>
                    )
                }

                return (
                    <div className={`swiper-slide ${s.swiperSlide}`}
                         key={slide.id}
                         style={{backgroundImage: `url(${imagePrepath(slide.image_name)})`}}>

                        <div className={s.slideTextWrp}>
                            {renderSliderInsides(slide)}
                        </div>

                    </div>
                )
            })
        }

        return (
            <div className={s.sliderWrp}>
                <div className={`w-100 h-100 ${s.bgGray}`}>
                    <Container className={'h-100'}>
                        <div className={'d-flex h-100 align-items-center'}>

                            <div ref={this.swiperRef} className={`swiper-container ${s.swiperContainer}`}>
                                <div className="swiper-wrapper">
                                    {slidesJSX()}
                                </div>
                            </div>

                            {/*<ContentBoxSlider*/}
                            {/*    onRenderPopupHanlder={this.props.onRenderPopupHanlder}*/}
                            {/*    currentSlideIndex={this.state.currentSlideIndex}*/}
                            {/*    setCurrentSlide={this.setCurrentSlide}*/}
                            {/*    slides={this.props.slides}*/}
                            {/*    getStr={this.props.getStr}*/}
                            {/*/>*/}
                        </div>

                    </Container>
                </div>
            </div>
        )
    }
}

export default HomeSlider;