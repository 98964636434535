import s from "./CartItem.module.css";
import React from "react";
import {Link} from "react-router-dom";
import {imagePrepath} from "../../../helpers/helpers";

const CartItems = props => {

    const cartItemsJSX = () => {
        if (props.cartData) {
            return props.cartData.map((cartItem, i) => {
                const price = Math.round(cartItem.price * 100) / 100;

                // creating customizables string

                let customizablesArr = [];
                if(cartItem.hasOwnProperty('children')){
                    // this is a collection's customizables

                }
                else {
                    // this is a single product's customizables
                    cartItem.customizables.forEach(custom => {
                        custom.packs.forEach(packItem => {
                            customizablesArr.push(`${packItem[`name`]}`);
                        })
                    });
                }

                const customizablesString = customizablesArr.join(', ');
                // /.



                return (
                    <div className={s.wrp}>
                        <div className={s.editBtn} onClick={() => {
                            props.onEditCartItem(i,cartItem.amount, cartItem, cartItem.id);
                        }}>
                            <i className={`fas fa-pencil-alt`} ></i>
                        </div>

                        <div className={s.removeBtn} onClick={() => props.onCartItemRemoved(i)}>
                            <i className={`fas fa-trash-alt`} ></i>
                        </div>

                        <div className={s.imageWrp}>
                            <div className={s.imageInnerWrp}>
                                <img src={imagePrepath(cartItem.image_name)}
                                     alt=""/>
                            </div>
                        </div>
                        <div className={'px-3 text-center'}>
                            <Link className={`${s.itemName} mb-2 mt-3`}>{cartItem.name} <span className={s.amount}>x{cartItem.amount}</span></Link>
                            <p className={s.itemOptions}>{customizablesString.length > 0 ? `+ ${customizablesString}` : ''}</p>
                            <div className={'d-flex align-items-center justify-content-center mt-2'}>
                                <p className={s.itemPrice}>{price} GEL</p>
                            </div>
                        </div>
                    </div>
                )

                // return (
                //    <tr className={s.wrp}>
                //        <td className={s.itemTitleWrp}>
                //           <Link className={s.itemName}>{cartItem.name} <span className={s.amount}>x{cartItem.amount}</span></Link>
                //            <p className={s.itemOptions}>{customizablesString.length > 0 ? `+ ${customizablesString}` : ''}</p>
                //        </td>
                //        <td className={s.itemPrice}>{price} GEL</td>
                //        <td className={s.itemActions}>
                //
                //        </td>
                //    </tr>
                // )
            })
        }
        return null;
    }

    return (<div className={`w-100`}>
        <div>
        {cartItemsJSX()}
        </div>
    </div>)
}

export default CartItems;