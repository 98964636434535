import React, {Component, createRef} from 'react';
import gsap from 'gsap';
import {Alert} from "react-bootstrap";
import s from './BusyModal.module.css';

class BusyModal extends Component {

    modalRef = createRef();

    constructor(props){
        super(props);
        console.log(props);
        this.state = this.getBusyState({}, props);
    }

    getBusyState = (stateObj, props) => {
        // getting busyDate as miliseconds
        let busyDate = localStorage.getItem('busyDate');
        // /.
        if(busyDate){
            busyDate = new Date(busyDate);
            if(busyDate.getTime() < new Date().getTime()){
                // time expired showing popup and setting new busyDate
                // saving busyDate as miliseconds
                localStorage.setItem('busyDate', busyDate.getTime() + 1000 * 60 * 10);
                stateObj.showAlert = props.generalData.main_page_alert !== '';
            } else {
                // not expired, popup should be hidden
                stateObj.showAlert = false;
            }
        } else {
            // initial load showing popup and setting new busyDate
            // saving busyDate as miliseconds
            const busyDate = new Date();
            localStorage.setItem('busyDate', busyDate.getTime() + 1000 * 60 * 10);
            stateObj.showAlert = props.generalData.main_page_alert && props.generalData.main_page_alert !== '';
        }

        return stateObj;
    }


    componentDidMount() {
        if(!this.state.showAlert){
            this.hideAlertAnimation(0)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((prevState.showAlert !== this.state.showAlert)){
            if(this.state.showAlert){
                gsap.to(this.modalRef.current, {
                    opacity: 1,
                    display: 'block',
                    duration: 0.5,
                });
            } else {
                this.hideAlertAnimation();
            }
        }
    }

    hideAlertAnimation = (duration = 0.3) => {
        gsap.to(this.modalRef.current, {
            opacity: 0,
            display: 'none',
            duration: duration,
        })
    }

    hideAlert = () => {
        this.setState({
            showAlert: false,
        })
    }

    render() {
        return (
            <div ref={this.modalRef} className={s.wrp}>
                <div className={s.overlay} onClick={this.hideAlert} />
                <div className={s.modalWrp}>
                    <div className={`${s.alert} alert`} role="alert">
                        <div className={s.closeBtn} onClick={this.hideAlert}>
                            <i className="fas fa-times"></i>
                        </div>
                        <div>{this.props.generalData.main_page_alert}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BusyModal;