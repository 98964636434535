import React from "react";
import Ingredient from "./Ingredient";

const Ingredients = props => {

    if (!props.selectedOptions) return null;

    const isCheckedFunc = (packId) => {
        let arr = [];
        if (props.selectedOptions.hasOwnProperty('options')) {
            arr = props.selectedOptions.options.filter(el => {
                return (el.optId === props.optId && el.packId === packId)
            });
        } else if (props.selectedOptions.hasOwnProperty('children')){
            arr = props.selectedOptions.children[props.childIndex].customizables.filter(el => {
                return (el.optId === props.optId && el.packId === packId)
            });
        }
        return arr.length > 0
    }


    const renderIngredients = () => {
        return props.packs.map((pack, i) => {
                const isChecked = isCheckedFunc(pack.id);

                const onIngredientClicked = () => {
                    if (props.hasOwnProperty('childIndex')) {
                        props.onIngredientClicked(props.optId, pack.id, props.type, props.childIndex);
                    } else {
                        props.onIngredientClicked(props.optId, pack.id, props.type);
                    }
                }

                return <Ingredient
                    key={pack.id}
                    type={props.type}
                    name={pack.name}
                    priceModif={pack.price_modifier}
                    onIngredientClicked={onIngredientClicked}
                    isChecked={isChecked}
                />
            }
        )
    }

    // const renderCollectionInsides = () => {
    //    return props.
    // }

    const renderInsides = () => {
        // if(props.selectedOptions.hasOwnProperty('options')){
        //    // simply render ingredients
        //    return renderIngredients();
        //
        // } else if(props.selectedOptions.hasOwnProperty('children')){
        //    // render collection insides -> products -> ingredients
        //    debugger;
        // }
        return renderIngredients();
    }

    return (
        <>
            {renderInsides()}
        </>
    )
}

export default Ingredients;