import React, {Component, createRef} from 'react';
import s from './Images.module.css';
import StackGrid from "react-stack-grid";
import {Container} from "react-bootstrap";
import {imagePrepath} from "../../../helpers/helpers";

import banner1 from '../../../assets/images/banner1.jpg';
import banner2 from '../../../assets/images/banner2.jpg';


class Images extends Component {

    state = {
        windowWidth: window.innerWidth,
        gridRef: null,
        loadedImages: null, // null/[true, false,...],
        imagesRefArr: null, // null/[React.ref,...]
        imageEventHandlers: null,
        eventSet: false, /// true/false
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({
                windowWidth: window.innerWidth
            })
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        this.handleStackGridLayoutUpdate();

        this.handleImagesLoadedArr();

        this.handleImagesLoadEventSetup();

    }

    componentWillUnmount() {
        this.handleImagesLoadEventRemoval();
    }

    // IMAGES LOAD EVENT REMOVAL
    handleImagesLoadEventRemoval = () => {
        const imageNodeList = document.querySelectorAll('.gallery-image');
        for (let i = 0; i < imageNodeList.length; i++) {
            imageNodeList[i].removeEventListener('load', this.state.imageEventHandlers[i]);
        }
    }
    // /.



    // IMAGE LOAD EVENT SETUP
    imagesLoadedCallback = (i) => {
        const newLoadedImagesArr = [...this.state.loadedImages];
        newLoadedImagesArr[i] = true;
        this.setState({
            loadedImages:  newLoadedImagesArr,
        })
    }

    handleImagesLoadEventSetup = () => {
        // if gridRef is defined, then it means that every img tag is rendered to the dom
        if(this.state.gridRef && !this.state.eventSet){

            const imageNodeList = document.querySelectorAll('.gallery-image');
            // storing load event handlers to remove the event handlers on componentWillUnmount
            // to avoid memory garbage
            const handlersArray = [];

            for (let i = 0; i < imageNodeList.length; i++) {
                const tempHandler = () => this.imagesLoadedCallback(i)
                handlersArray.push(tempHandler);
                imageNodeList[i].addEventListener('load', tempHandler);
            }

            this.setState({
                eventSet: true,
                imageEventHandlers: handlersArray,
            })
        }
    }
    // /.

    handleStackGridLayoutUpdate = () => {
        if(
            this.state.gridRef
            && Array.isArray(this.state.loadedImages)
            && this.state.loadedImages.findIndex(el => el === false) === -1
        ){
            this.state.gridRef.updateLayout();
        }
    }

    handleImagesLoadedArr = () => {
        if(
            !this.state.loadedImages
            && Array.isArray(this.props.gallery)
            && this.props.gallery.length > 0
        ){
            // populating image refs array and loaded images array based on props.gallery array.
            this.setState({
                loadedImages: this.generateArray(this.props.gallery.length, () => false),
            })
            // /.
        }
    }


    generateArray(arrLength, fillFunc){
        return new Array(arrLength).fill(fillFunc())
    }

    renderGalleryImages = () => {


        const imagesJSX = this.props.gallery.map((el,i) => {
            // assigning ref to each image from imageRefArr array
            // to later monitor loading of each image
            return (
                <div key={el.id} className={s.gridItem}>
                    <img
                        className={`gallery-image`}
                        src={imagePrepath(el.image_name)}/>
                </div>
            )
            // /.
        });
        return imagesJSX;
    }

    render() {
        // debugger;

        if(Array.isArray(this.props.gallery) && this.props.gallery.length === 0){
            return null;
        }

        const stackResponsiveSize = () => {
            let size = '33.3%';
            if(this.state.windowWidth <= 992){
                size = '50%';
            }
            return size;
        }

        return (
            <div className={s.wrp}>
                <Container>
                    <h1 className={s.title}>{this.props.getStr('gallery_title') || 'გალერეა'}</h1>
                    <StackGrid
                        gridRef={grid => {
                            this.setState({
                                gridRef: grid,
                            })
                        }}
                        columnWidth={stackResponsiveSize()}
                    >
                        {this.renderGalleryImages()}
                    </StackGrid>
                </Container>
            </div>
        );
    }
}


export default Images;