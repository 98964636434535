import React from "react";
import s from './About.module.css';
import TitleBanner from "../common/TitleBanner/TitleBanner";
import bannerImg from "../../assets/images/banner1.jpg";
import aboutImg from '../../assets/images/about.jpg';
import {Col, Container, Row} from "react-bootstrap";
import {imagePrepath} from "../../helpers/helpers";

const About = props => {
   return (
      <div>
         <TitleBanner imageURL={bannerImg} text={'About Us'} />
         <Container className={s.wrp}>
            <Row>
               <Col md={'6'}>
                  <img src={aboutImg} className={'w-100'} alt=""/>
               </Col>

               <Col md={'6'}>
                  <h3 className={`${s.title}`}>საუკეთესო კერძები თბილისში</h3>
                  <p className={s.par}>
                     ლორემ იპსუმ იმდენჯერ ღუღუნებს ჩაკლული ცაცუნით ჩამოეკიდები პროტესტანტები. ნაცოლარი პროტესტანტები თხრა ჩაკლული მიყვარხართ მობინადრები ფესვიანად საკვირველია სიჩუმეა შეეშინდებოდა ვარდიკოებით. ხერხით საკვირველია განუვითარებელი ჭიანჭველა ცაცუნით მიყვარხართ, ჟანრმა, წყალა ერთმანეთისადმი, ტრანსიტ ჩაყვინთულს. მიყვარხართ შემხვედრია ერთმანეთისადმი ჟანრმა ტრანსიტ სეირნობით. ჩამოეკიდები ნაშინდარისკენ ყელზე მსაჯული დის, ვარდიკოებით გალავნიდან წვალებამ ქონიან.
                     ნაფოტებად ეზიზღები რადიოსადგურები ანდერძს კუნძულიც, ქარქაშს, სრულყოფილად სხვა იყო აგისრულდეთ დაჭრეს უსაქმური.
                  </p>
               </Col>
            </Row>
         </Container>
      </div>
   )
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default About;