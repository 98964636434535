import React from 'react';
import {Col, Row} from "react-bootstrap";
import s from "./Branch.module.css";
import ContactMap from "./ContactMap";

const Branch = (props) => {
    return (
        <Row className={s.wrp}>

            <Col md={'6'} className={'pr-md-0 px-0 pl-auto order-md-2'}>
                <div className={s.mapWrp}>
                    <ContactMap
                        mapMarker={{
                            centerX: props.latitude,
                            centerY: props.longitude,
                        }}
                        zoom={15}
                    />
                </div>
            </Col>

            <Col md={'6'}>
                <div className={'py-5 p-4'}>
                    <h3 className={`${s.title}`}>{props.address}</h3>
                    <ul className={s.contactData}>
                        <li className={s.contactItem}>{props.getStr('phone')}: <a
                            href={'tel:' + props.phone}>{props.phone}</a></li>
                        <li className={s.contactItem}>{props.getStr('email')}: <a
                            href={'mailto:' + props.email}>{props.email}</a></li>
                    </ul>
                    <div className={'d-flex mt-3'}>
                        <a href={props.general.facebook_link} target='_blank' rel="noopener noreferrer"
                           className={s.iconWrp}>
                            <i className="fab fa-facebook-f"></i>
                        </a>

                        <a href={props.general.instagram_link} target='_blank' rel="noopener noreferrer"
                           className={s.iconWrp}>
                            <i className="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>
            </Col>

        </Row>
    );
};

export default Branch;