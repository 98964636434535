import React, {Component, createRef} from "react";
import {connect} from "react-redux";
import {
   closePopup, decrementAmount, editCartItemPopup,
   incrementAmount,
   onAddIngredient,
   onRemoveIngredient,
   renderPopup
} from '../../store/reducers/popupReducer'
import Popup from "./Popup";
import {gsap} from 'gsap';
import {addCartItem, removeCartItem} from "../../store/reducers/cartReducer";
import {optionsIsCollection} from "../../helpers/helpers";

const withPopup = (Comp) => {

   class popupHoc extends Component {

      state = {
         showDropdowns: [],
         showIngDropdown: false,
         initedDropdowns: false,
         showPopup: false,
      }

      ingDropdownRef = createRef();
      popupWrpRef = createRef();
      popupBoxRef = createRef();

      dropdownRefs = [];

      componentDidMount() {
         this.initPopup();
      }

      componentDidUpdate(prevProps, prevState, snapshot) {
         if (prevState.showPopup !== this.state.showPopup) {
            if (this.state.showPopup) {
               // preventing body from scrolling, used in order to not conflict with popup's scroll
               // that overflows the screen
               document.body.style.overflowY = "hidden";

               this.showPopupAnimation();
               // this.changeDropdownState(true);
            } else {
               // re-enabling body scroll
               document.body.style.overflowY = "auto";

               this.closePopupAnimation();
               // this.changeDropdownState(false);

               // reset local dropdowns show state
               this.setState({showDropdowns: []});
            }
         }

         this.handleDropdowns(prevProps, prevState);


      }


      // popup SHOW/HIDE animation methods
      initPopup = () => {
         this.props.onClosePopup();
         // ;
         //init styles for popup reveal animation
         const popupWrpRef = this.popupWrpRef.current;
         const popupBoxRef = this.popupBoxRef.current;

         gsap.set(popupWrpRef, {display: 'none', opacity: 0});
         gsap.set(popupBoxRef, {opacity: 0, scaleX: 0.8, scaleY: 0.8, x: '-50%'});
      }

      showPopupAnimation = () => {
         const popupWrpRef = this.popupWrpRef.current;
         const popupBoxRef = this.popupBoxRef.current;

         //animation wrapper
         gsap.set(popupWrpRef, {display: 'block'});
         gsap.to(popupWrpRef, {opacity: 1, duration: 0.2});

         //animation popupbox
         gsap.to(popupBoxRef, {opacity: 1, scaleX: 1, scaleY: 1, duration: 0.2});
      }

      closePopupAnimation = () => {
         const popupWrpRef = this.popupWrpRef.current;
         const popupBoxRef = this.popupBoxRef.current;

         //animation wrapper
         gsap.to(popupWrpRef, {
            opacity: 0, duration: 0.2, onComplete: () => {
               gsap.set(popupWrpRef, {display: 'none'});
            }
         });

         //animation popupbox
         gsap.to(popupBoxRef, {opacity: 0, scaleX: 0.8, scaleY: 0.8, duration: 0.2});
      }
      ///.


      //INGREDIENTS
      // dropdown SHOW/HIDE animation methods
      initDropdown = () => {
         if (this.state.showDropdowns.length > 0 && !this.state.initedDropdowns) {
            /* this is point when options have already synched and rendered and need to be initialized
               using dropdownRefs array and gsap
            */

            //this function inizializes every dropdown for animation using gsap
            this.dropdownRefs.forEach((dropdownRef) => {
               gsap.set(dropdownRef.current, {height: 0, overflow: 'hidden'});
            })

            //need to change this state val so that this if clause doesn't execute on every update
            this.setState({
               initedDropdowns: true,
            });
         }
      }


      onToggleDropdownState = (index) => {
         const newShowDropdowns = this.state.showDropdowns.map((el, i) => {
            if (i === index) {
               return !el
            }
            return el;
         });
         // ;
         this.setState({
            showDropdowns: newShowDropdowns,
         })

      }

      syncDropdownRefs = () => {
         if (this.props.options) {
            if (optionsIsCollection(this.props.options)) {
               this.dropdownRefs = []

               this.props.options.children.forEach(child => {
                  child.customizables.forEach(el => {
                     this.dropdownRefs.push(createRef())
                  })
               })

            } else {
               this.dropdownRefs = this.props.options.singleProdOptions.map(opt => {
                  return createRef();
               });
            }
         }

      }

      animateDropdowns = () => {
         this.dropdownRefs.forEach((dropdownRef, i) => {
            dropdownRef = dropdownRef.current;
            if (this.state.showDropdowns[i]) {
               const dropdownScrollHeight = dropdownRef.scrollHeight;
               gsap.to(dropdownRef, {height: dropdownScrollHeight + 'px', duration: 0.2});
            } else {
               gsap.to(dropdownRef, {height: 0, duration: 0.2});
            }
         });
      }

      handleDropdowns = (prevProps, prevState) => {

         /* this is point when options have already synched and rendered and need to be initialized
            using dropdownRefs array and gsap
         */
         this.initDropdown();

         if (prevProps.options !== this.props.options) {
            if (this.state.showPopup) {
               // executes when popup is visible
               if (this.state.showDropdowns.length === 0) {
                  // executes when local showDropdowns state has not yet been synced with REDUX options and is an empty array
                  if (this.props.options) {
                     if (optionsIsCollection(this.props.options)) {
                        const dropdownsArr = [];
                        this.props.options.children.forEach((child) => {
                           for (let x = 0; x < child.customizables.length; x++) {
                              dropdownsArr.push(false);
                           }
                        })

                        this.setState({
                           showDropdowns: dropdownsArr
                        })


                     } else {
                        this.setState({
                           showDropdowns: this.props.options.singleProdOptions.map(() => false)
                        });
                     }
                  }
                  // sync dropdownRefs array to store refs for all dropdowns
                  this.syncDropdownRefs();
               }
            } else {
               // executes when popup closes and options in redux are wiped
               this.setState({
                  showDropdowns: [],
                  initedDropdowns: false,
               })
            }
         }

         if (prevState.showDropdowns !== this.state.showDropdowns && this.state.initedDropdowns) {
            // identify if this state array has changed which means that we should animate
            // dropdowns according to new state
            this.animateDropdowns();
         }

      }


      onIngredientClicked = (optId, packId, type, childIndex = null) => {


         // method checks if this pack of this option is already selected
         const isSelected = () => {
            let res = -1;
            if (this.props.selectedOptions.hasOwnProperty('options')) {
               // single product
               res = this.props.selectedOptions.options.findIndex((opt) => {
                  return opt.optId === optId && packId === opt.packId;
               })
            } else if (this.props.selectedOptions.hasOwnProperty('children')) {
               // collection
               res = this.props.selectedOptions.children[childIndex].customizables.findIndex((opt) => {
                  return opt.optId === optId && packId === opt.packId;
               });
            }
            return res !== -1;
         }
         // /.

         // if childIndex is not NULL then we have a collection
         const ingredientDict = {
            type,
            optObj: {
               optId,
               packId,
            }
         }

         if (childIndex !== null && !isNaN(childIndex)) {
            ingredientDict.childIndex = childIndex;
         }

         if (isSelected()) {
            this.props.onRemoveIngredient(ingredientDict);
         } else {
            this.props.onAddIngredient(ingredientDict);
         }
      }
      ///.

      onClosePopupHandler = () => {
         this.setState({showPopup: false})
         this.props.onClosePopup()
      }

      onRenderPopupHandler = (prod_id, name, desc, price, image_link, selectedOptions) => {
         this.setState({showPopup: true})
         this.props.onRenderPopup(prod_id, name, desc, price, image_link, selectedOptions)
      }

      onEditCartItemHandler = (index, amount, selectedOptions, id) => {
         const callback = () => {
                this.setState({
                   showPopup: true,
                })
             }

         ;

         this.props.onEditCartItem(index, amount, selectedOptions, id, callback)
      }

      render() {

         const selectedOptions = this.props.selectedOptions || [];

         const countSingleProductTotalPrice = (argSelectedOptions, startPrice, options) => {
            return argSelectedOptions.reduce((accum, curVal, i) => {
               const packsArr = options.filter(opt => opt.id === curVal.optId)[0].packs;
               const priceModifier = packsArr.filter(pack => pack.id === curVal.packId)[0].price_modifier;
               return accum + parseFloat(priceModifier) * 100;
            }, startPrice);
         }


         let totalPrice = 0;
         if (selectedOptions.hasOwnProperty('options')) {
            // count for single product
            totalPrice = countSingleProductTotalPrice(selectedOptions.options, this.props.price * 100, this.props.options.singleProdOptions);
         } else if (selectedOptions.hasOwnProperty('children')) {
            // count for collection
            totalPrice = selectedOptions.children.reduce((accum, curVal, i) => {
               return accum + countSingleProductTotalPrice(
                   selectedOptions.children[i].customizables,
                   0,
                   this.props.options.children[i].customizables
               );
            }, 0);
            totalPrice += this.props.price * 100;

         }
         totalPrice = Math.round(totalPrice) / 100;


         const totalDiscountedPrice = () => {
            if (this.props.discountedPrice) {
               let totalPrice = 0;
               if (selectedOptions.hasOwnProperty('options')) {
                  // count for single product
                  totalPrice = countSingleProductTotalPrice(selectedOptions.options, this.props.discountedPrice * 100, this.props.options.singleProdOptions);
               } else if (selectedOptions.hasOwnProperty('children')) {
                  // count for collection
                  totalPrice = selectedOptions.children.reduce((accum, curVal, i) => {
                     return accum + countSingleProductTotalPrice(
                         selectedOptions.children[i].customizables,
                         0,
                         this.props.options.children[i].customizables
                     );
                  }, 0);
                  totalPrice += this.props.discountedPrice * 100;
               }
               return totalPrice / 100;
            }
            return null;
         }

         const addCartItemHandler = () => {
            if (!this.props.editMode) {
               // add cart item
               this.props.addCartItem(
                   this.props.id,
                   this.props.amount,
                   this.props.selectedOptions
               );
               this.onClosePopupHandler();

            } else {
               // edit cart item (remove and add again with new options)
               this.props.removeCartItem(this.props.index)
                   .then(() => {
                      this.props.addCartItem(
                          this.props.id,
                          this.props.amount,
                          this.props.selectedOptions
                      )
                      this.onClosePopupHandler();
                   })
            }
         }

         return (
             <>
                <Popup
                    general={this.props.general}

                    name={this.props.name}
                    desc={this.props.desc}
                    image_link={this.props.image_link}
                    price={totalPrice}
                    discountedPrice={totalDiscountedPrice()}
                    options={this.props.options}
                    showDropdowns={this.state.showDropdowns}
                    selectedOptions={this.props.selectedOptions}
                    editMode={this.props.editMode}

                    onClosePopup={this.onClosePopupHandler}
                    onRenderPopup={this.onRenderPopupHandler}
                    addToCartCallback={addCartItemHandler}
                    onEditCartItem={this.onEditCartItemHandler}

                    popupWrpRef={this.popupWrpRef}
                    popupBoxRef={this.popupBoxRef}

                    dropdownRefs={this.dropdownRefs}
                    onDropdownTitleClicked={this.onToggleDropdownState}
                    onIngredientClicked={this.onIngredientClicked}

                    amount={this.props.amount}
                    onIncrementAmount={this.props.onIncrementAmount}
                    onDecrementAmount={this.props.onDecrementAmount}
                />

                <Comp
                    onClosePopupHandler={this.onClosePopupHandler}
                    onRenderPopupHandler={this.onRenderPopupHandler}
                    onEditCartItemHandler={this.onEditCartItemHandler}
                    {...this.props}
                />

             </>
         )
      }
   }

   const mapStateToProps = (state) => ({
      id: state.popup.id,
      showPopup: state.popup.showPopup,
      options: state.popup.options,
      selectedOptions: state.popup.selectedOptions,
      amount: state.popup.amount,

      name: state.popup.name,
      desc: state.popup.desc,
      price: state.popup.price,
      discountedPrice: state.popup.discounted_price,

      image_link: state.popup.image_link,

      editMode: state.popup.editMode,
      index: state.popup.index,

      general: state.app.generalData,
   });

   const mapDispatchToProps = (dispatch) => ({
      onClosePopup: () => dispatch(closePopup()),
      onRenderPopup: (prod_id, name, desc, price, image_link, selectedOptions) => {
         return dispatch(
             renderPopup(prod_id, name, desc, price, image_link, selectedOptions)
         )
      },
      onAddIngredient: (dict) => dispatch(onAddIngredient(dict)),
      onRemoveIngredient: (dict) => dispatch(onRemoveIngredient(dict)),
      onIncrementAmount: () => dispatch(incrementAmount()),
      onDecrementAmount: () => dispatch(decrementAmount()),
      addCartItem: (id, amount, customizables) => dispatch(addCartItem(id, amount, customizables)),
      removeCartItem: (index) => dispatch(removeCartItem(index)),
      onEditCartItem: (index, amount, selectedOptions, id, callback) => dispatch(editCartItemPopup(index, amount, selectedOptions, id, callback)),
   });

   return connect(mapStateToProps, mapDispatchToProps)(popupHoc);
}

export default withPopup;