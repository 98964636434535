import React, {Component, createRef} from "react";
import HomeSlider from "./HomeSlider/HomeSlider";
import CategorySlider from "./CategorySlider/CategorySlider";
import FeaturedProducts from "./FeaturedProducts/FeaturedProducts";
import withPopup from "../../hoc/withPopup/withPopup";
import {initHomepage} from "../../store/reducers/homePageReducer";
import {connect} from "react-redux";
import Loader from "../common/Loader/Loader";
import WithStr from "../../hoc/withStr";
import s from "./Home.module.css";
import gsap from "gsap/gsap-core";
import {clearCart} from "../../store/reducers/cartReducer";
import Images from "./Images/Images";
import Locations from "./Locations/Locations";
import {compose} from "redux";
import {showLocationModal} from "../../store/reducers/appReducer";

class Home extends Component {
    state = {
        inputVal: '',
        pageLoaded: false,
        paymentModalText: '',
    }

    checkoutSuccessRef = createRef();
    failedPayment = ['FAILED', 'REVERSED', 'AUTOREVERSED', 'TIMEOUT'];
    pendingPayment = ['CREATED', 'PENDING'];
    decilnedPayment = ['DECLINED'];
    successfulPayment = ['OK'];

    changeRedirectModalText = (paymentStatusArr, responseMessageArr, paymentStatus) => {
        for (let i = 0; i < paymentStatusArr.length; i++) {
            const index = paymentStatusArr[i].findIndex(el => {
                return el === paymentStatus.toUpperCase();
            })
            if (index !== -1) {
                this.setState({
                    paymentModalText: responseMessageArr[i]
                })
                return;
            }
        }

        // default error handler
        this.setState({
            paymentModalText: responseMessageArr[0]
        })
    }

    initHomepageHandler = () => {
        this.props.initHomepage()
            .then(() => {
                this.setState({
                    pageLoaded: true,
                })
            })
    }

    componentDidMount() {
        this.initHomepageHandler();

        gsap.set(this.checkoutSuccessRef.current, {display: 'none'});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log(this.props);
        const searchParams = new URLSearchParams(window.location.search);

        if (this.state.pageLoaded && this.state.pageLoaded !== prevState.pageLoaded && searchParams.get('payment')) {

            this.changeRedirectModalText(
                [this.failedPayment, this.pendingPayment, this.decilnedPayment, this.successfulPayment],
                [this.props.getStr('checkout_failed'), this.props.getStr('checkout_pending'), this.props.getStr('checkout_declined'), this.props.getStr('checkout_success')],
                searchParams.get('payment')
            )


            this.props.clearCart();
            gsap.to(this.checkoutSuccessRef.current, {display: 'flex', opacity: 1});
            setTimeout(() => {
                gsap.to(this.checkoutSuccessRef.current, {
                    display: 'none', opacity: 0,
                });
            }, 3000);
        }

    }


    render() {

        return (
            <div>
                {!this.state.pageLoaded ? <Loader /> : null}

                <div ref={this.checkoutSuccessRef} className={s.checkoutSuccessWrp}>
                    <div className={s.bgDarkTransparent}></div>
                    <div className={s.checkoutSuccessText}>{this.state.paymentModalText}<i
                        className="fas fa-check-circle ml-2"></i></div>
                </div>

                <HomeSlider
                    slides={this.props.slides}
                    onRenderPopupHanlder={this.props.onRenderPopupHandler}
                    getStr={this.props.getStr}
                />


                <CategorySlider categories={this.props.categories}/>

                <FeaturedProducts
                    addCartItem={this.props.addCartItem}
                    featuredProducts={this.props.featuredProducts}
                    onRenderPopup={
                        this.props.branchData
                            ? this.props.onRenderPopupHandler
                            : this.props.showLocationModal
                    }
                    getStr={this.props.getStr}
                />

                {/*<StepsSection />*/}

                <Images
                    gallery={this.props.gallery}
                    getStr={this.props.getStr}
                />


                <Locations
                    getStr={this.props.getStr}
                    branches={this.props.branches}
                />


            </div>
        )
    }
}

const mapStateToProps = state => ({
    featuredProducts: state.homePage.featuredProducts,
    categories: state.homePage.categories,
    specialOffers: state.homePage.specialOffers,
    slides: state.homePage.slides,
    blog: state.homePage.blog,
    gallery: state.homePage.gallery,
    branches: state.homePage.branches,
    init: state.homePage.init,
    branchData: state.app.branchData,
})

const mapDispatchToProps = dispatch => ({
    initHomepage: () => dispatch(initHomepage()),
    clearCart: () => dispatch(clearCart()),
    showLocationModal: () => dispatch(showLocationModal())
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    WithStr,
    withPopup
)(Home);