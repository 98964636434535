import React, {Component} from 'react';
import Jobs from './Jobs/Jobs';
import TitleBanner from "../common/TitleBanner/TitleBanner";
import bannerImg from "../../assets/images/3.jpg";
import {connect} from "react-redux";
import {getJobs, getSingleJob} from "../../store/reducers/careerReducer";
import Loader from "../common/Loader/Loader";
import s from './Career.module.css'
import {Container} from "react-bootstrap";
import ScrollToTop from "../common/ScrollToTop/ScrollToTop";
import withStr from "../../hoc/withStr";
import {imagePrepath, pageImageNames} from "../../helpers/helpers";
import bannerPlaceholder from "../../assets/images/banner-placeholder.jpg";

class Career extends Component {

    state = {
        showLoader: true,
    }

    componentDidMount() {
        this.props.getJobs()
            .then(res => {
                this.setState({
                    showLoader: false,
                })
            });
    }

    render() {

        if (this.state.showLoader) {
            return <Loader/>
        }

        const imageName = this.props.general[pageImageNames.career];
        const bannerImagePath = imageName ? imagePrepath(imageName) : bannerPlaceholder;

        return (
            <div>
                <ScrollToTop/>
                <TitleBanner imageURL={bannerImagePath} text={this.props.getStr('nav_career')}/>
                <div className={`mt-3 ${s.wrp}`}>
                    <Container>
                        <Jobs getStr={this.props.getStr} jobs={this.props.jobs}/>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    getJobs: () => dispatch(getJobs()),
    getSingleJob: () => dispatch(getSingleJob())
});

const mapStateToProps = state => ({
    general: state.app.generalData,
    jobs: state.career.jobs,
    singleJobData: state.career.singleJobData,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStr(Career));