import React from "react";
import s from './Product.module.css';
import {Col} from "react-bootstrap";
import BtnPriceSide from "../common/BtnPriceSide/BtnPriceSide";

const Product = props => {
    // id: 2,
    // name: 'Beef Stake #2',
    // desc: 'Beef, Carrot, Cheese, French Fries',
    // price: '15.99',
    // imageLink: 'http://assets.suelo.pl/soup/img/products/product-pizza.jpg',
   let boxShadowClass = '';
   if(props.boxShadow){
      boxShadowClass += s.boxShadow;
   }

   const renderPrice = () => {
      if(props.hasOwnProperty('discountedPrice') && props.discountedPrice){
         return (
             <div>
                <small><del>{props.price} GEL</del></small>
                <div className={`font-weight-bold`}>{props.discountedPrice} GEL</div>
             </div>
         )
      }
      return <div className={`font-weight-bold`}>{props.price} GEL</div>
   }

   const productInsides = (
      <div className={`${boxShadowClass} d-flex flex-column h-100`}>
         <div className={s.imgWrp}>
            <img src={props.imageLink} className={s.img} alt=""/>
         </div>
         <div className={s.wrp}>
            <div>
               <p className={s.name}>{props.name}</p>
               <p className={s.desc}>{props.desc}</p>
            </div>
            <div className={`d-flex justify-content-between ${s.buyWrp}`}>
               {renderPrice()}
               <div className={s.buyBtn} onClick={props.callback}>
                  <i className="fas fa-plus"></i>
               </div>
            </div>
         </div>
      </div>
   )

   if(props.sizeMixins) {
      return (
         <Col {...props.sizeMixins} className={`${props.className}`}>
            {productInsides}
         </Col>
      )
   }

   return <Col md={6} lg={4} className={`${props.className}`}>
      {productInsides}
   </Col>


}

export default Product;