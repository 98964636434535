import React from 'react';
import MessengerCustomerChat from "react-messenger-customer-chat";

const FbMessenger = (props) => {
    if(!props.pageId){
        return null;
    }

    return (
        <div>
            <MessengerCustomerChat
                pageId={props.pageId}
            />
        </div>
    );
};

export default FbMessenger;